import React from 'react';
import {City} from 'TYPES/State';
import {useDispatch, useSelector} from 'STORE/store';
import {setActiveCity} from 'STORE/slices/Geo';
import GTM from '../../ui/google/GTM';

type CityProps = {
  item: City;
  onClick: () => void;
}
export const CityButton = ({item, onClick}: CityProps) => {
  const dispatch = useDispatch();
  const {activeCity} = useSelector((state) => state.geo);

  const click = () => {
    GTM.push({
      action: 'popUpClickElement',
      label: `${item.name} | Попап выбора города`
    });

    dispatch(setActiveCity(item));
    onClick();
  }

  let className = 'city-button';
  if (item.isMain) className += ' bold';
  if (activeCity.id === item.id) className += ' active';

  return <button className={className} onClick={click}>{item.name}</button>
}
