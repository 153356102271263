import './Map.scss';

import React, {useEffect, useState} from 'react';
import {YaMaps, YaPin} from '../ui/yandex-maps/YaMaps';
import {useDispatch, useSelector} from 'STORE/store';
import {getMapItems} from 'STORE/thunks/map-thunk';
import {Select} from '../ui/form/select/Select';
import {MapItemType} from 'TYPES/State';
import {CitiesPopupButton} from '../cities-popup/CitiesPopupButton';
import GTM from '../ui/google/GTM';
import {RouterModule} from '../router';
import {useGTMLabel} from 'HOOKS/useGTMLabel';


const selectItems: {id: MapItemType | 'all', name: string}[] = [
  {
    id: 'all',
    name: 'Все'
  },
  {
    id: 'giper',
    name: 'Гипермаркеты'
  },
  {
    id: 'syper',
    name: 'Супермаркеты'
  },
  {
    id: 'mini',
    name: 'Минимаркеты'
  },
]
const names: {[key: string]: string} = {};
selectItems.forEach(item => names[item.id] = item.name);

export const Map: RouterModule = ({module}) => {
  const [active, setActive] = useState<MapItemType | 'all'>('all');
  const {id} = useSelector((state => state.geo.activeCity));
  const items = useSelector((state => state.geo.mapItems));
  const dispatch = useDispatch();
  const label = useGTMLabel(module);

  useEffect(() => {
    if (id) dispatch(getMapItems(id));
  }, [id]);

  const icon = {
    iconImageHref: require('./Pin.svg'),
    iconImageSize: [56, 64],
    iconImageOffset: [-28, -64]
  }

  const onChange = (values: string[]) => {
    if (values[0] && active !== values[0]) {
      GTM.push({
        action: 'clickTabs',
        label: `${names[values[0]]} | ${label}`
      });

      setActive(values[0] as MapItemType | 'all');
    }
  }

  return <div className='map'>
    <div className="wrapper">
      <h4>Магазины «Лента» в&nbsp;вашем городе</h4>

      <div className="map-controls">
        <Select items={selectItems}
                className='invert map-select'
                defaultValue={['all']}
                onChange={onChange}
        />
        <div className="map-controls-right">
          <div className="map-controls-text">Ваш город:</div>
          <CitiesPopupButton gtmLabelName={label}/>
        </div>
      </div>
    </div>
    <div className='yandex-map'>
      <YaMaps center={[55.76, 37.64]}>
        {items.map(({lat, lon, name, type}, i) => {
          if (active !== 'all' && active !== type) return null;

          return <YaPin lat={+lat}
                        lng={+lon}
                        label={name}
                        icon={icon}
                        key={'yp-' + i}/>
        })}
      </YaMaps>
    </div>
  </div>
}