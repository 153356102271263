import {createAsyncThunk} from "@reduxjs/toolkit";
import {http} from 'a-utils';
import {CitiesPayload, State} from 'TYPES/State';

export const getCities = createAsyncThunk<CitiesPayload, undefined, {state: State}>(
  'geo/cities',
  async (arg, thunkAPI) => {
    const {api} = thunkAPI.getState().options;
    const {rejectWithValue} = thunkAPI;

    try {
      return await http<CitiesPayload>({
        action: api + 'cities/'
      });
    } catch (error) {
      // if (error !== 422) throw error;
      return rejectWithValue({});
    }
  });