import './promo.scss';
import './main-cards.scss';
import {PromoSnowfall} from "../promo-snowfall/PromoSnowfall";
import React, {useEffect, useState} from 'react';
import router, {RouterModule} from '../router';
import {useSelector} from 'STORE/store';
import {Slider} from '../slider/Slider';
import {getAnchor, useGTMLabel} from 'HOOKS/useGTMLabel';
import {AnchorButton} from '../anchor/AnchorButton';
import useGlobalDOMEvents from "HOOKS/useGlobalDOMEvents";
import Iframe from 'react-iframe'

const links = ["calendar", "gifts", "solutions", "catalog"];
const images = [require('./images/4@2x.png'), require('./images/2@2x.png'), require('./images/1@2x.png'),  require('./images/3@2x.png')];
// const links = ["solutions", "gifts", "catalog"];
// const images = [require('./images/1@2x.png'), require('./images/2@2x.png'),  require('./images/3@2x.png')];

export const Promo = ({module}) => {
  const {modules} = useSelector(state => state.blocks);
  const label = useGTMLabel(module);
  const [isMobileT, setIsMobileT] = useState(window.innerWidth < 1025);

  useGlobalDOMEvents({
    'resize': () => {
      setIsMobileT(window.innerWidth < 1025)
    }
  });

  const MainCardLink = ({moduleTo, label, isActive, index}) => {
    let className = 'main-card';
    if (isActive) className += ' active';

    const route = router[moduleTo];
    const {navLink} = route;
    const name = navLink.name;

    return <AnchorButton className={className} gtmLabelName={label} to={moduleTo}>
      <img src={images[index]} alt=""/>
      <div className='main-card-inner' dangerouslySetInnerHTML={{__html: name}}/>
    </AnchorButton>
  }

  let navCards;
  const linkElements = links
      .filter((link, i) => modules.includes(link) && router[link].navLink)
      .map((moduleTo, i) => (
              <MainCardLink moduleTo={moduleTo}
                            label={`${label}`}
                            key={`${moduleTo} + ${i}`}
                            index={i}
              />
          )
      )

  navCards = isMobileT ?
      <Slider gtmLabelName={label} className='main-slider' slidesPerView={'auto'} spaceBetween={20} sliderType={"swipe"}>{linkElements}</Slider> :
      <div className="main-cards">{linkElements}</div>

  return (
      <div className={`promo promo--anima`}>
        {!isMobileT  && <PromoSnowfall/>}
        {/*<Iframe url="https://music.yandex.ru/iframe/#playlist/christina.bilyalova@redcollar.ru/1000"*/}
        {/*        width="100%"*/}
        {/*        height="450px"*/}
        {/*        id="myId"*/}
        {/*        className="myClassname"*/}
        {/*        display="initial"*/}
        {/*        position="relative"/>*/}
        {/*<PromoSnowfall/>*/}
        <div className="promo__wrapper">
          <div className="promo__angles">
            {/*<img className="img" src={require('./images/bottom-left/il-template.png')} alt="" />*/}
            <div className="promo__top-left">
              <div className="promo__tl promo__tl-back"><img width={867} height={292} src={require('./images/top-left/back.svg')} alt="" /></div>
              <div className="promo__tl promo__tl-left"><img width={681} height={280} src={require('./images/top-left/left2.svg')} alt="" /></div>
              <div className="promo__tl promo__tl-right"><img width={627} height={112} src={require('./images/top-left/right.svg')} alt="" /></div>
              <div className="promo__tl promo__tl-star1"><img src={require('./images/top-left/Star1@2x.png')} alt="" /></div>
              <div className="promo__tl promo__tl-front"><img width={820} height={188} src={require('./images/top-left/front.svg')} alt="" /></div>
              <div className="promo__tl promo__tl-ball"><img src={require('./images/top-left/Ball@2x.png')} alt="" /></div>
              <div className="promo__tl promo__tl-star2"><img src={require('./images/top-left/Star2@2x.png')} alt="" /></div>
            </div>
            <div className="promo__top-right">
              <div className="promo__tr promo__tr-cloud1"><img width={614} height={199} src={require('./images/top-right/cloud1.png')} alt="" /></div>
              <div className="promo__tr promo__tr-candy"><img src={require('./images/top-right/Candy.png')} alt="" /></div>
              <div className="promo__tr promo__tr-cloud2"><img width={881} height={331} src={require('./images/top-right/cloud2.png')} alt="" /></div>
              <div className="promo__tr promo__tr-cloud3"><img width={715} height={143} src={require('./images/top-right/cloud3.svg')} alt="" /></div>
              <div className="promo__tr promo__tr-ball"><img src={require('./images/top-right/Ball.png')} alt="" /></div>
              <div className="promo__tr promo__tr-cloud4"><img width={705} height={240} src={require('./images/top-right/cloud4.svg')} alt="" /></div>
              <div className="promo__tr promo__tr-star"><img src={require('./images/top-right/Star@2x.png')} alt="" /></div>
              <div className="promo__tr promo__tr-cloud5"><img width={479} height={107} src={require('./images/top-right/cloud5.png')} alt="" /></div>
            </div>
            <div className="promo__bottom-left">
              <div className="promo__bl promo__bl-elca1"><img src={require('./images/bottom-left/elca1.png')} alt="" /></div>
              <div className="promo__bl promo__bl-elca3"><img src={require('./images/bottom-left/elca3@2x.png')} alt="" /></div>
              <div className="promo__bl promo__bl-cloud1"><img width={662} height={247} src={require('./images/bottom-left/cloud1.svg')} alt="" /></div>
              <div className="promo__bl promo__bl-cloud4"><img width={662} height={247} src={require('./images/bottom-left/cloud1.svg')} alt="" /></div>
              <div className="promo__bl promo__bl-house"><img width={152} height={127} src={require('./images/bottom-left/house.svg')} alt="" /></div>
              <div className="promo__bl promo__bl-cloud2"><img width={794} height={297} src={require('./images/bottom-left/cloud2.svg')} alt="" /></div>
              <div className="promo__bl promo__bl-cloud5"><img width={794} height={297} src={require('./images/bottom-left/cloud2.svg')} alt="" /></div>
              <div className="promo__bl promo__bl-elca2"><img src={require('./images/bottom-left/elca2@2x.png')} alt="" /></div>
              <div className="promo__bl promo__bl-gift"><img src={require('./images/bottom-left/gift@2x.png')} alt="" /></div>
              <div className="promo__bl promo__bl-cloud6"><img width={638} height={278} src={require('./images/bottom-left/cloud3.png')} alt="" /></div>
              <div className="promo__bl promo__bl-cloud3"><img width={638} height={278} src={require('./images/bottom-left/cloud3.png')} alt="" /></div>
            </div>
            <div className="promo__bottom-left promo__bottom-left--mobile"><img width={125} height={253} src={require('./images/bottom-left/mobile@2x.png')} alt="" /></div>
            <div className="promo__bottom-right">
              <div className="promo__br promo__br-ball"><img src={require('./images/bottom-right/Ball@2x.png')} alt="" /></div>
              <div className="promo__br promo__br-star"><img src={require('./images/bottom-right/Star.png')} alt="" /></div>
              <div className="promo__br promo__br-candy"><img src={require('./images/bottom-right/Candy@2x.png')} alt="" /></div>
              <div className="promo__br promo__br-cloud1"><img width={873} height={303} src={require('./images/bottom-right/cloud1.svg')} alt="" /></div>
              <div className="promo__br promo__br-elca"><img src={require('./images/bottom-right/elca@2x.png')} alt="" /></div>
              <div className="promo__br promo__br-gift"><img src={require('./images/bottom-right/gift@2x.png')} alt="" /></div>
              <div className="promo__br promo__br-cloud3"><img width={765} height={286} src={require('./images/bottom-right/cloud3.svg')} alt="" /></div>
              <div className="promo__br promo__br-cloud2"><img width={873} height={303} src={require('./images/bottom-right/cloud2.svg')} alt="" /></div>
              <div className="promo__br promo__br-gingerbread"><img src={require('./images/bottom-right/gingerbread@2x.png')} alt="" /></div>
              <div className="promo__br promo__br-cloud4"><img width={800} height={299} src={require('./images/bottom-right/cloud4.svg')} alt="" /></div>
              <div className="promo__br promo__br-cloud5"><img width={703} height={326} src={require('./images/bottom-right/cloud5.svg')} alt="" /></div>
            </div>
            <div className="promo__bottom-right promo__bottom-right--mobile"><img width={201} height={274} src={require('./images/il--bottom-right-mobile@2x.png')} alt="" /></div>
            <div className="promo__top-mobile">
              <div className="promo__m promo__m-middle"><img width={658} height={204} src={require('./images/top-mobile/middle@2x.png')} alt="" /></div>
              <div className="promo__m promo__m-ball"><img src={require('./images/top-mobile/Ball@2x.png')} alt="" /></div>
              <div className="promo__m promo__m-left"><img width={120} height={211} src={require('./images/top-mobile/left@2x.png')} alt="" /></div>
              <div className="promo__m promo__m-star"><img src={require('./images/top-mobile/Star@2x.png')} alt="" /></div>
              <div className="promo__m promo__m-right"><img width={193} height={148} src={require('./images/top-mobile/right@2x.png')} alt="" /></div>
              <div className="promo__m promo__m-candy"><img src={require('./images/top-mobile/Candy@2x.png')} alt="" /></div>
            </div>
          </div>
          <div className="promo__content wrapper">
            <div className="promo__main-title">
              <div className="promo__name">
                <img className="template" src={require('./images/promo__name.svg')} alt=""/>
                <img className="promo__letter l1" src={require('./images/letters/1.svg')} alt=""/>
                <img className="promo__letter l2" src={require('./images/letters/2.svg')} alt=""/>
                <img className="promo__letter l3" src={require('./images/letters/3.svg')} alt=""/>
                <img className="promo__letter l4" src={require('./images/letters/4.svg')} alt=""/>
                <img className="promo__letter l5" src={require('./images/letters/5.svg')} alt=""/>
                <img className="promo__letter l6" src={require('./images/letters/6.svg')} alt=""/>
                <img className="promo__letter l7" src={require('./images/letters/7.svg')} alt=""/>
                <img className="promo__letter l8" src={require('./images/letters/8.svg')} alt=""/>
                <img className="promo__letter l9" src={require('./images/letters/9.svg')} alt=""/>
                <img className="promo__letter l10" src={require('./images/letters/10.svg')} alt=""/>
                <img className="promo__letter l11" src={require('./images/letters/11.svg')} alt=""/>
              </div>
              <div className="promo__under-title">
                <img className="promo__line promo__line--left" src={require('./images/promo__line-left.svg')} alt=""/>
                <div className="promo__title-snowflake">
                  <img src={require('./images/promo__snowFlake.svg')} alt=""/>
                </div>
                <img className="promo__line promo__line--right" src={require('./images/promo__line-right.svg')} alt=""/>
              </div>
              <div className="promo__sign sign gradient">Готовимся к празднику с&#160;Лентой!</div>
            </div>
          </div>
        </div>

          <div className="promo__nav">
            {navCards}
          </div>

      </div>
  );
};
