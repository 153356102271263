import './battle.scss'

import React, {useState} from 'react';
import {useGTMLabel} from "HOOKS/useGTMLabel";
import {LinkPDF} from "../links/LinkPDF";
import useGlobalDOMEvents from "HOOKS/useGlobalDOMEvents";

export const Battle = ({module}) => {
  const label = useGTMLabel(module);
  const [isMobileT, setIsMobileT] = useState(window.innerWidth < 768);


  useGlobalDOMEvents({
    'resize': () => {
      setIsMobileT(window.innerWidth < 768)
    }
  });

  return (
      <div className="battle">
        {isMobileT
            ?
            <div className="battle__cloud">
              <div className="cloud">
                <img width="480" height="228" src={require('./images/Snow@2x.png')} alt=""/>
              </div>
            </div>
            :
            <div className="battle__cloud">
              <div className="ball">
                <img width="53" height="132" src={require('./images/Ball@2x.png')} alt=""/>
              </div>
              <div className="star">
                <img width="80" height="233" src={require('./images/Star@2x.png')} alt=""/>
              </div>
              <div className="cloud">
                <img width="919" height="212" src={require('./images/snow-desktop.svg')} alt=""/>
              </div>
            </div>
        }
        <div className="battle__wrapper">
          <div className="battle__content">
            {isMobileT ?
                <div className="battle__cards">
                  <div className="battle__card battle__card--oliv">
                    <div className="battle__card-content wrapper">
                      <div className="battle__card-title">
                        <h6 className="battle__head battle__head--cashback battle__head--white">Кешбэк</h6>
                        <h6 className="battle__head battle__head--medium battle__head--yellow">«Оливье»</h6>
                      </div>
                      <div className="battle__card-text"><p>Вернем 99% баллами на Карту № 1 за покупку ингредиентов для самого новогоднего салата!*</p></div>
                      <div className="battle__card-sign"><p>*при покупке от 2х любых позиций из одной товарной категории в одном чеке</p></div>
                      <div className="battle__card-image">
                        <img src={require('./images/1@2x.png')} alt="Кешбэк «Оливье»"/>
                      </div>
                    </div>
                    <div className="battle__card-description">
                      <div className="battle__card-descr wrapper">
                        <p className="battle__descr-top">Срок проведения Акции с 01.12.21 по 14.01.22</p>
                        <div className="battle__descr-body">
                          <p className="battle__descr-main">Начисление баллов 01.01.22</p>
                          <p className="battle__descr-main">Использование баллов до 14.01.22 включительно</p>
                        </div>
                        <LinkPDF gtmLabelName={label} to={'/tmp/Cashback_Olivie_pravila_ot_03.12.pdf'} className="battle__descr-bottom">
                          Смотреть календарь Акции и полные правила
                        </LinkPDF>
                      </div>
                    </div>
                  </div>
                  <div className="battle__card battle__card--ny">
                    <div className="battle__card-content wrapper">
                      <div className="battle__card-title">
                        <h6 className="battle__head battle__head--small battle__head--white">Новогодний</h6>
                        <h6 className="battle__head battle__head--cashback battle__head--yellow">Кешбэк</h6>
                      </div>
                      <div className="battle__card-text"><p>Вернем 50% баллами на Карту № 1 за покупку продуктов для новогоднего стола!*</p></div>
                      <div className="battle__card-sign"><p>*при покупке от 2х любых позиций из одной товарной категории в одном чеке</p></div>
                      <div className="battle__card-image">
                        <img src={require('./images/2@2x.png')} alt="Новогодний Кешбэк"/>
                      </div>
                    </div>
                    <div className="battle__card-description">
                      <div className="battle__card-descr wrapper">
                        <p className="battle__descr-top">Срок проведения Акции с 20.12.21 по 14.01.22</p>
                        <div className="battle__descr-body">
                          <p className="battle__descr-main">Начисление баллов 01.01.22</p>
                          <p className="battle__descr-main">Использование баллов до 14.01.22 включительно</p>
                        </div>
                        <LinkPDF gtmLabelName={label} to={'/tmp/Novogodniy_Cashback_pravila_ot_03.12.pdf'} className="battle__descr-bottom">
                          Смотреть календарь Акции и полные правила
                        </LinkPDF>
                      </div>
                    </div>
                  </div>
                  {/*<div className="battle__cards-before">*/}
                  {/*  <img src={}>*/}
                  {/*</div>*/}
                </div>
            :
                <div className="battle__cols">
                  <div className="battle__cards wrapper">
                    {!isMobileT &&
                    <div className="battle__snowflakes">
                      <div className="battle-snowflake bs1"><img src={require('./images/s1.svg')} alt=""/></div>
                      <div className="battle-snowflake bs2"><img src={require('./images/s1.svg')} alt=""/></div>
                      <div className="battle-snowflake bs3"><img src={require('./images/s1.svg')} alt=""/></div>
                      <div className="battle-snowflake bs4"><img src={require('./images/s1.svg')} alt=""/></div>
                      <div className="battle-snowflake bs5"><img src={require('./images/s1.svg')} alt=""/></div>
                      <div className="battle-snowflake bs6"><img src={require('./images/s1.svg')} alt=""/></div>
                      <div className="battle-snowflake bs7"><img src={require('./images/s1.svg')} alt=""/></div>
                      <div className="battle-snowflake bs8"><img src={require('./images/s1.svg')} alt=""/></div>
                      <div className="battle-snowflake bs9"><img src={require('./images/s1.svg')} alt=""/></div>
                      <div className="battle-snowflake bs10"><img src={require('./images/s1.svg')} alt=""/></div>
                      <div className="battle-snowflake bs11"><img src={require('./images/s1.svg')} alt=""/></div>
                      <div className="battle-snowflake bs12"><img src={require('./images/s1.svg')} alt=""/></div>
                    </div>
                    }
                    <div className="before">
                      <div>
                        <img src={require('./images/cloud-b-left.svg')} alt=""/>
                      </div>
                    </div>
                    <div className="battle__card battle__card--oliv">
                      <div className="battle__card-content">
                        <div className="battle__card-title">
                          <h6 className="battle__head battle__head--cashback battle__head--white">Кешбэк</h6>
                          <h6 className="battle__head battle__head--medium battle__head--yellow">«Оливье»</h6>
                        </div>
                        <div className="battle__card-text"><p>Вернем 99% баллами на Карту № 1 за покупку ингредиентов для самого новогоднего салата!*</p></div>
                        <div className="battle__card-sign"><p>*при покупке от 2х любых позиций из одной товарной категории в одном чеке</p></div>
                        <div className="battle__card-image">
                          <img src={require('./images/1@2x.png')} alt="Кешбэк «Оливье»"/>
                        </div>
                      </div>
                    </div>
                    <div className="battle__card battle__card--ny">
                      <div className="battle__card-content">
                        <div className="battle__card-title">
                          <h6 className="battle__head battle__head--small battle__head--white">Новогодний</h6>
                          <h6 className="battle__head battle__head--cashback battle__head--yellow">Кешбэк</h6>
                        </div>
                        <div className="battle__card-text"><p>Вернем 50% баллами на Карту № 1 за покупку продуктов для новогоднего стола!*</p></div>
                        <div className="battle__card-sign"><p>*при покупке от 2х любых позиций из одной товарной категории в одном чеке</p></div>
                        <div className="battle__card-image">
                          <img src={require('./images/2@2x.png')} alt="Новогодний Кешбэк"/>
                        </div>
                      </div>
                    </div>
                    <div className="battle__middle-decor">
                      <div className="tree">
                        <img  src={require('./images/Tree@2x.png')} alt=""/>
                      </div>
                      <div className="cloud">
                        <img src={require('./images/middle-cloud@2x.png')} alt=""/>
                      </div>
                      <div className="card">
                        <img src={require('./images/Card.svg')} alt=""/>
                      </div>
                    </div>
                    <div className="after">
                      <div>
                        <img src={require('./images/cloud-b-right.svg')} alt=""/>
                      </div>
                    </div>
                  </div>
                  <div className="battle__card-descriptions">
                    <div className="wrapper">
                      <div className="battle__card-descr">
                        <p className="battle__descr-top">Срок проведения Акции с 01.12.21 по 14.01.22</p>
                        <div className="battle__descr-body">
                          <p className="battle__descr-main">Начисление баллов 01.01.22</p>
                          <p className="battle__descr-main">Использование баллов до 14.01.22 включительно</p>
                        </div>
                        <LinkPDF gtmLabelName={label} to={'/tmp/Cashback_Olivie_pravila_ot_03.12.pdf'} className="battle__descr-bottom">
                          Смотреть календарь Акции и полные правила
                        </LinkPDF>
                      </div>
                      <div className="battle__card-descr">
                        <p className="battle__descr-top">Срок проведения Акции с 20.12.21 по 14.01.22</p>
                        <div className="battle__descr-body">
                          <p className="battle__descr-main">Начисление баллов 01.01.22</p>
                          <p className="battle__descr-main">Использование баллов до 14.01.22 включительно</p>
                        </div>
                        <LinkPDF gtmLabelName={label} to={'/tmp/Novogodniy_Cashback_pravila_ot_03.12.pdf'} className="battle__descr-bottom">
                          Смотреть календарь Акции и полные правила
                        </LinkPDF>
                      </div>
                    </div>

                  </div>
                </div>
            }
          </div>
        </div>
      </div>
  );
};
