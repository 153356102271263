import {Modules} from 'TYPES/State';
import router from '../components/router';
// import {useSelector} from 'STORE/store';

export const getAnchor = (module: Modules, modules?: Modules[]) => ` | #${module}_${router[module].anchorIndex}`;

export function useGTMLabel(module: Modules) {
  // const {modules} = useSelector(state => state.blocks);
  const {gtmLabelName} = router[module];

  return `${gtmLabelName}${getAnchor(module)}`;
}