import './Banners.scss';

import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'STORE/store';
import {getBanners} from 'STORE/thunks/banners-thunk';
import {Slider} from '../slider/Slider';
import {LinkDefault} from '../links/LinkDefault';
import {BannerSlide} from './BannerSlide';
import {RouterModule} from '../router';
import {useGTMLabel} from 'HOOKS/useGTMLabel';


export const Banners: RouterModule = ({module}) => {
  const dispatch = useDispatch();
  const slides = useSelector(state => state.blocks.banners);
  const label = useGTMLabel(module);

  useEffect(() => {
    dispatch(getBanners());
  }, []);

  if (!slides || !slides.length) return null;

  return <div className="banners dynamic-block">
    <div className="banners__wrapper wrapper">
      {/*<h3>{label}</h3>*/}

      <div className="banners-container">
        {slides.map((s, i) => {
          return <BannerSlide {...s} gtmLabelName={label} key={'pi-' + i}/>
        })}
      </div>
      {/*<Slider*/}
      {/*  name={label}*/}
      {/*  slidesPerView='auto'*/}
      {/*>*/}
      {/*  {slides.map((s, i) => {*/}
      {/*    return <BannerSlide {...s} name={`${label} | ${s.name}`} key={'pi-' + i}/>*/}
      {/*  })}*/}
      {/*</Slider>*/}
    </div>
  </div>
}