import './music.scss'

import React, {useEffect} from 'react';
import Iframe from 'react-iframe'
import Delayed from "../utils/Delayed";
import {useGTMLabel} from "HOOKS/useGTMLabel";
import {LinkDefault} from "../links/LinkDefault";

export const Music = ({module}) => {
  const label = useGTMLabel(module);

  return (
      <section className="section music">
        <div className="music__toys">
          <div className="music__toy music__toy-star"><img src={require('./images/Star@2x.png')} alt="" /></div>
          <div className="music__toy music__toy-headphones"><img src={require('./images/Headphones@2x.png')} alt="" /></div>
          <div className="music__toy music__toy-candy"><img src={require('./images/Candy@2x.png')} alt="" /></div>
          <div className="music__toy music__toy-ball"><img src={require('./images/Ball@2x.png')} alt="" /></div>
        </div>
          <div className="music__wrapper">
            <div className="music__decorate">
              <div className="template">
                <img width={564} height={357} src={require('./images/il--mobile@2x.png')} alt=""/>
              </div>
              <div className="template-pc">
                <img width={1287} height={1046} src={require('./images/il--desktop@2x.png')} alt=""/>
              </div>
              <div className="template-front">
                <img width={1103} height={470} src={require('./images/front-cloud@2x.png')} alt=""/>
              </div>
              <div className="snow snow-1"><img width={14} height={14} src={require('./images/snow1.svg')} alt=""/></div>
              <div className="snow snow-2"><img width={28} height={29} src={require('./images/snow2.svg')} alt=""/></div>
              <div className="snow snow-3"><img width={15} height={16} src={require('./images/snow3.svg')} alt=""/></div>
            </div>
            <div className="music__heading heading heading--gradient wrapper">
              <div className="heading__titles">
                <h2 className="h2"><span className="gradient">Новогодний</span></h2>
                <h2 className="h2"><span className="gradient">Плейлист</span></h2>
                <h2 className="h2"><span className="gradient">от Ленты</span></h2>
              </div>
              <p className="heading__sign sign">Мы отобрали самые новогодние песни для создания атмосферы праздника</p>
            </div>
            <div className="music__content wrapper">

              <div className="music__iframe">
                <Delayed>
                  <Iframe url="https://music.yandex.ru/iframe/#playlist/muzyka.novogodnyaya/1000"
                          width="100%"
                          height="500px"
                          id="yandex-music__iframe"
                          display="initial"
                          position="relative"/>
                </Delayed>
              </div>

              <div className="music__another-way">
                <div className="music__title">Слушайте на ресурсах:</div>
                <ul className="music__list">
                  <li>
                    <LinkDefault to={`https://music.yandex.ru/users/muzyka.novogodnyaya/playlists/1000`}
                                 gtmLabelName={label}
                                 className="music__link"

                    >
                      <img src={require("./images/yandex-music.svg")} alt="yandex-music"/>
                      <span className="gradient">Яндекс.Музыка</span>
                    </LinkDefault>
                  </li>
                  <li>
                    <LinkDefault to={`https://open.spotify.com/playlist/2VMSljThb1p9ja2ND9CkNv?si=cf311a03597647d7`}
                                 gtmLabelName={label}
                                 className="music__link"

                    >
                      <img src={require("./images/spotify.svg")} alt="spotify"/>
                      <span className="gradient">Spotify</span>
                    </LinkDefault>
                  </li>
                  <li>
                    <LinkDefault to={`https://music.apple.com/ru/playlist/%D0%BD%D0%BE%D0%B2%D1%8B%D0%B9-%D0%B3%D0%BE%D0%B4-%D1%81-%D0%BB%D0%B5%D0%BD%D1%82%D0%BE%D0%B9/pl.u-06oxDl3s2J8Xeb`}
                                 gtmLabelName={label}
                                 className="music__link"
                    >
                      <img src={require("./images/apple-music.svg")} alt="apple-music"/>
                      <span className="gradient">Apple music</span>
                    </LinkDefault>
                  </li>
                </ul>
              </div>


            </div>
          </div>
      </section>
  );
};
