import React from 'react';

export const ButtonLoader = (props) => {
  let className = props.className ? props.className + ' button button--loader' : 'button button--loader';

  return (
      <button className={className}>
        <div className="button__icon">
          <div className="button__inner">
            <div>
              <div className="ball"/>
              <div className="ball"/>
              <div className="ball"/>
            </div>
            <div>
              <div/>
              <div/>
              <div/>
            </div>
          </div>
        </div>
        <div className="button__text">Подобрать</div>
      </button>
  );
};;