import './CitiesPopup.scss';

import React, {useState} from 'react';
import Scrollbar from '../ui/scrollbar/Scrollbar';
import {CityButton} from './CityButton/CityButton';
import {Popup} from '../ui/popup/Popup';
import {useDispatch, useSelector} from 'STORE/store';
import {setCitiesPopupActive} from 'STORE/slices/Geo';
import useGlobalDOMEvents from 'HOOKS/useGlobalDOMEvents';
import {isTouch} from 'a-utils';


const calc = () => window.innerWidth < 768 || isTouch();

export const CitiesPopup = () => {
  const [isMobile, setIsMobile] = useState(calc());
  const {mainCities, citiesGroups, citiesPopupActive} = useSelector((state) => state.geo);
  const dispatch = useDispatch();

  useGlobalDOMEvents({
    'resize': () => {
      setIsMobile(calc())
    }
  });

  const setPopupActive = (isActive: boolean) => {
    dispatch(setCitiesPopupActive(isActive));
  }

  let returned = <>
    <div className='cities-popup-main'>
      {mainCities.map((c, i) => (
        <CityButton item={c} onClick={() => setPopupActive(false)} key={'cpm-' + i}/>
      ))}
    </div>

    <div className='cities-popup-all'>
      {citiesGroups.map((g, j) => (
        <div className='cities-popup-group' key={'cpg-' + j}>
          <div className="cities-popup-group-name bold">{g.group}</div>
          <div className='cities-popup-group-inner'>
            {g.cities.map((c, i) => (
              <CityButton item={c} onClick={() => setPopupActive(false)} key={'cpgi-' + i}/>
            ))}
          </div>
        </div>
      ))}
    </div>
  </>;

  if (isMobile) {
    returned = <div className='cities-popup-scroll'>{returned}</div>;
  } else {
    returned = <Scrollbar className='cities-popup-scroll'>{returned}</Scrollbar>;
  }

  return <Popup name='Попап выбора города' onClose={() => setPopupActive(false)} active={citiesPopupActive}>
    <div className="cities-popup">
      <div className="cities-popup-top">
        <h5>Пожалуйста, выберите свой город</h5>
      </div>

      {returned}
    </div>
  </Popup>
}