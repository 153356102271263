//global

import {EventEmitter, throttle} from 'a-utils';


const eventEmitter = new EventEmitter<ResizeData>();
const forcedEventEmitter = new EventEmitter<ResizeData>();


export type ResizeEvent = 'fire-resize' |'resize-disable' | 'resize-enable';
export type Size = {
  width: number;
  height: number;
  grad: -90 | 90 | 0 | 180
}
export type ResizeData = {
  type: 'change' | undefined,
  _module: 'resize.store'
} & Size;

let initialized: boolean = false;
let disabled: boolean = false;

let size: Size = {
  width: 0,
  height: 0,
  grad: 0
};



function _windowSize() : Size {
  let width: number,
      height: number;

  if (document.documentElement && ( document.documentElement.clientWidth || document.documentElement.clientHeight )) {
    width = document.documentElement.clientWidth;
    height = document.documentElement.clientHeight;
  } else {
    // console.dir(window);
    width = window.innerWidth;
    height = window.innerHeight;
  }

  return {
    height: height,
    width: width,
    grad: 0
  }
}


function _change() {
  size = _windowSize();

  const screenOrientation = window.screen.orientation;

  if (screenOrientation) {
    if (screenOrientation.type === 'landscape-primary') {
      size.grad = -90;
    } else if (screenOrientation.type === 'landscape-secondary') {
      size.grad = 90;
    } else if (screenOrientation.type === 'portrait-primary') {
      size.grad = 0;
    } else if (screenOrientation.type === 'portrait-secondary') {
      size.grad = 180;
    }
  } else {
    if (size.width > size.height) {
      size.grad = 90;
    } else {
      size.grad = 0;
    }
  }

  forcedEventEmitter.dispatch(_getData('change'));

  if (!disabled) eventEmitter.dispatch(_getData('change'));
}

const _throttledChange = throttle(_change, 300);


function _handleEvent(e: ResizeEvent) {
  switch (e) {
    case 'fire-resize':
      _throttledChange();
      break;
    case 'resize-disable':
      disabled = true;
      break;
    case 'resize-enable':
      disabled = false;
      break;
  }
}


function _getData(type: 'change' | undefined): ResizeData {
  return {
    type: type,
    _module: 'resize.store',
    ...size
  };
}



function _init() {
  size = _windowSize();

  window.addEventListener('resize', _change, false);
  window.addEventListener('orientationchange', _change, false);
  window.addEventListener('load', _change, false);

  initialized = true;
}


if (!initialized) _init();

export default {
  eventEmitter,
  forcedEventEmitter,
  getData: _getData.bind(null, undefined),
  emit: _handleEvent
};