import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import ResizeStore from '../../components/utils/resize/resize.store';
import {DynamicBlocks} from 'TYPES/State';
import {getModules} from 'STORE/thunks/modules-thunk';
import {getPartners} from 'STORE/thunks/partners-thunk';
import {getBanners} from 'STORE/thunks/banners-thunk';
import {getCatalog} from "STORE/thunks/catalog-thunk";
import {getSolutions} from "STORE/thunks/solutions-thunk";
import {getGifts} from "STORE/thunks/gifts-thunk";
import {getFilters} from "STORE/thunks/g-filters-thunk";
import {getCalendar} from "STORE/thunks/calendar-thunk";
import {getCrazyBanners} from "STORE/thunks/crazy-thunk";
import {getActions} from "STORE/thunks/actions-thunk";


const initialState: DynamicBlocks = {
  modules: [],
  partners: null,
  crazy: null,
  banners: null,
  catalog: null,
  solutions: null,
  calendar: null,
  gifts: null,
  filters: null,
  actions: null,
};

const blocksSlice = createSlice({
  name: 'blocks-reducer',
  reducers: {
  },
  // async reducers
  extraReducers: (builder) => {
    builder.addCase(getModules.pending, (state, action) => {
      console.log('loading modules');
    })
    builder.addCase(getModules.fulfilled, (state: DynamicBlocks, action) => {
      // state.modules = ['main', 'black-friday', 'sale-access', 'big-sale', 'black-november', 'registration', 'black-cashback', 'partners', 'slider-banners', 'winners', 'mobile-apps', 'map', 'footer'];
      state.modules = ['promo','calendar', 'solutions', 'gifts', 'catalog', 'partners', 'mobile-apps', 'cashback', 'map', 'footer', 'filters', 'crazy-link'];
      ResizeStore.emit('fire-resize');
    })
    builder.addCase(getModules.rejected, (state: DynamicBlocks, action) => {
      console.log(action);
    })

    builder.addCase(getCalendar.pending, (state, action) => {
      console.log('loading calendar');
    })
    builder.addCase(getCalendar.fulfilled, (state: DynamicBlocks, action) => {
      state.calendar = action.payload;
      ResizeStore.emit('fire-resize');
    })
    builder.addCase(getCalendar.rejected, (state: DynamicBlocks, action) => {
      console.log(action);
    })

    builder.addCase(getPartners.pending, (state, action) => {
      console.log('loading partners');
    })
    builder.addCase(getPartners.fulfilled, (state: DynamicBlocks, action) => {
      state.partners = action.payload;
      ResizeStore.emit('fire-resize');
    })
    builder.addCase(getPartners.rejected, (state: DynamicBlocks, action) => {
      console.log(action);
    })

    builder.addCase(getBanners.pending, (state, action) => {
      console.log('loading banners');
    })
    builder.addCase(getBanners.fulfilled, (state: DynamicBlocks, action) => {
      state.banners = action.payload;
      ResizeStore.emit('fire-resize');
    })
    builder.addCase(getBanners.rejected, (state: DynamicBlocks, action) => {
      console.log(action);
    })


    builder.addCase(getCatalog.pending, (state, action) => {
      console.log('loading catalog');
    })
    builder.addCase(getCatalog.fulfilled, (state: DynamicBlocks, action) => {
      state.catalog = action.payload;
      ResizeStore.emit('fire-resize');
    })
    builder.addCase(getCatalog.rejected, (state: DynamicBlocks, action) => {
      console.log(action);
    })

    builder.addCase(getSolutions.pending, (state, action) => {
      console.log('loading solutions');
    })
    builder.addCase(getSolutions.fulfilled, (state: DynamicBlocks, action) => {
      state.solutions = action.payload;
      ResizeStore.emit('fire-resize');
    })
    builder.addCase(getSolutions.rejected, (state: DynamicBlocks, action) => {
      console.log(action);
    })


    builder.addCase(getGifts.pending, (state, action) => {
      console.log('loading gifts');
    })
    builder.addCase(getGifts.fulfilled, (state: DynamicBlocks, action) => {
      state.gifts = action.payload;
      ResizeStore.emit('fire-resize');
    })
    builder.addCase(getGifts.rejected, (state: DynamicBlocks, action) => {
      console.log(action);
    })

    builder.addCase(getFilters.pending, (state, action) => {
      console.log('loading filters');
    })
    builder.addCase(getFilters.fulfilled, (state: DynamicBlocks, action) => {
      state.filters = action.payload;
      ResizeStore.emit('fire-resize');
    })
    builder.addCase(getFilters.rejected, (state: DynamicBlocks, action) => {
      console.log(action);
    })

    builder.addCase(getCrazyBanners.pending, (state, action) => {
      console.log('loading filters');
    })
    builder.addCase(getCrazyBanners.fulfilled, (state: DynamicBlocks, action) => {
      state.crazy = action.payload;
      ResizeStore.emit('fire-resize');
    })
    builder.addCase(getCrazyBanners.rejected, (state: DynamicBlocks, action) => {
      console.log(action);
    })

    builder.addCase(getActions.pending, (state, action) => {
      console.log('loading filters');
    })
    builder.addCase(getActions.fulfilled, (state: DynamicBlocks, action) => {
      state.actions = action.payload;
      ResizeStore.emit('fire-resize');
    })
    builder.addCase(getActions.rejected, (state: DynamicBlocks, action) => {
      console.log(action);
    })
  },
  initialState: initialState
});

export const {} = blocksSlice.actions;
export default blocksSlice;
