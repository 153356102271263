import React from 'react';
import {LinkDefault} from "../../links/LinkDefault";

export const  SliderCardItem = (itemLinkData) => {

  const fields = {
    label: itemLinkData.gtmLabelName,
    starIndex: itemLinkData.dataIndex,
    imageRef: itemLinkData.itemImage,
    link: itemLinkData.itemLink,
    text: itemLinkData.itemDescription,
    collection :itemLinkData.collection
  }

  return (
      <li className="slider-card__toggle-item">
        <LinkDefault className="slider-card__toggle-link" to={fields.link}  gtmLabelName={`(${fields.collection}) | ${fields.label}`}>
          <span className="slider-card__toggle-preview">
            <span className="slider-card__toggle-star">
              <svg width="24" height="24" viewBox="0 0 42 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M25.2213 1.08362C25.8064 0.512899 26.792 0.847195 26.9091 1.65615L28.5568 13.031C28.7402 14.2973 29.5184 15.3994 30.6502 15.996L40.7993 21.3461C41.5209 21.7265 41.508 22.7643 40.7771 23.1266L30.4767 28.2333C29.3332 28.8002 28.5289 29.8788 28.3117 31.1365L26.3536 42.4782C26.2146 43.2831 25.2216 43.5909 24.6517 43.0058L16.6486 34.7881C15.7545 33.8701 14.4748 33.436 13.2067 33.6206L1.85553 35.2733C1.04728 35.391 0.44649 34.5425 0.825885 33.8192L6.17219 23.6267C6.76506 22.4964 6.78294 21.1511 6.22033 20.0054L1.15252 9.68589C0.792941 8.95369 1.41411 8.12218 2.21824 8.25932L13.5278 10.1881C14.7891 10.4032 16.0771 10.0019 16.9931 9.10857L25.2213 1.08362Z" fill="#074690"/>
              </svg>
              <span className="slider-card__toggle-index">{fields.starIndex + 1}</span>
            </span>
            <span className="slider-card__toggle-image">
              <img src={fields.itemImage} alt=""/>
            </span>
          </span>
          <span className="slider-card__toggle-text">
            {fields.text}
          </span>
        </LinkDefault>
      </li>
  );
};
