import './YaMaps.scss';

import React, {useEffect, useState} from 'react';
import {useSelector} from 'STORE/store';


const script = document.createElement('script');
let initialized = false;
let loaded = false;


type YaPinProps = {
  lat: number;
  lng: number;
  label?: string;
  map?: ymaps.Map;
  icon?: {
    iconImageHref: string;
    iconImageSize: number[];
    iconImageOffset: number[];
  }
}
type YaPinType = React.FunctionComponent<YaPinProps>;

type Props = {
  children?: ReturnType<typeof YaPin> | (ReturnType<typeof YaPin> | null)[] | null;
  center?: [number, number];
}

export const YaPin: YaPinType = ({lat, lng, label, map, icon}) => {
  const [pin, setPin] = useState<ymaps.Placemark | null>(null);

  useEffect(() => {
    if (!map) return;

    const p = new ymaps.Placemark([lat, lng], {
      hintContent: label
    }, {
      iconLayout: 'default#image',
      zIndex: 10000 - Math.round(lat * 1000),
      ...icon
    });

    setPin(p);

    map.geoObjects.add(p);

    return () => {
      map.geoObjects.remove(p);
    }
  }, [map]);
  useEffect(() => {
    if (!pin) return;

    pin.geometry?.setCoordinates([lat, lng]);
    pin.options.set({
      hintContent: label
    })

  }, [lat, lng, label]);

  return null;
}


export const YaMaps = ({children, center}: Props) => {
  const keys = useSelector(state => state.options.keys);
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const [map, setMap] = useState<ymaps.Map | null>(null);

  if (children && !Array.isArray(children)) children = [children];

  useEffect(() => {
    if (!ref || !keys || !keys.YandexMaps) return;

    const init = () => {
      ymaps.ready(() => {
        const m = new ymaps.Map(ref, {
          center,
          zoom: 10,
          behaviors: ["drag", "multiTouch"],
          controls: ["zoomControl", "fullscreenControl"]
        }, {
          suppressMapOpenBlock: true,
          nativeFullscreen: true,
        });

        m.margin.setDefaultMargin(100);

        setMap(m)
      })
    }

    if (!initialized) {
      script.defer = true;
      script.async = true;
      script.src = `https://api-maps.yandex.ru/2.1/?lang=ru_RU&amp;apikey=${keys.YandexMaps}`;

      document.head.appendChild(script);

      initialized = true;
    }

    if (loaded) {
      init();
    } else {
      script.addEventListener('load', init);
    }

    return () => {
      if (map) map.destroy();
      script.removeEventListener('load', init);
    }
  }, [ref, keys]);

  useEffect(() => {
    if (map) {
      const bounds = map.geoObjects.getBounds();
      if (bounds) map.setBounds(bounds).then(() => {
        map.setZoom(Math.min(map.getZoom(), 15))
      });
    }
  }, [children])


  return <div className='ya-maps' ref={setRef}>
    {children && children.map((child, i) => {
      if (!child) return;
      const props: YaPinProps = {
        ...child.props,
        map
      }
      return React.cloneElement(child, props);
    })}
  </div>
}