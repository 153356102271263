import './window.scss';

import React, {useEffect, useState} from 'react';
import dayjs from "dayjs";
import {getFinalIcon} from "./Icons";
import {LinkDefault} from "../links/LinkDefault";
import useGlobalDOMEvents from "HOOKS/useGlobalDOMEvents";

export const CalendarWindow = ({windowIndex, category, isOfferEnd, isOfferWait, dateOpen, dateStart, dateEnd, description, notice, textBottom, onInteractive, onPopupOpened, gtmLabelName}) => {
  const [isMobileT, setIsMobileT] = useState(window.innerWidth < 1025);
  const [isVisible, setVisibility] = useState(false);
  const [isMobileVisible, setVisibilityOnMobile] = useState(true);
  const [isStatusWithClass, setStatusWithClass] = useState('soon');
  let formattedDate;

  useGlobalDOMEvents({
    'resize': () => {
      setIsMobileT(window.innerWidth < 1025)
    }
  });

  if (dateStart && dateEnd){
    let startDate = dayjs(dateStart).format('D');
    let endDate = dayjs(dateEnd).format('D');
    let startMonth = dayjs(dateStart).month();
    let endMonth = dayjs(dateEnd).month();

    if (startDate && !endDate){
      formattedDate = `c ${startDate} декабря`
    } else if (!startDate && endDate) {
      formattedDate = `до ${endDate} декабря`
    } else if (startDate === endDate) {
      formattedDate = `${startDate} декабря`
    } else if (startMonth !== endMonth){
      formattedDate = `${startDate} декабря - ${endDate} января`
    } else {
      formattedDate = `${startDate}-${endDate} декабря`
    }
  }

  useEffect(()=>{
    if (isOfferEnd){
      setStatusWithClass('overdue');
    } else if (isOfferWait) {
      setStatusWithClass('soon');
    } else {
      setStatusWithClass('active');
      formattedDate = `${dayjs(dateStart).format('D')} - ${dayjs(dateEnd).format('D')}`
    }
  }, []);


  let popupContent;
  if (isStatusWithClass === 'overdue') {
    popupContent =
        <div className="window__popup-wrapper">
          {dateEnd
            ?  <p className="window__main window__text window__text--bold">Этот сюрприз был доступен до {dayjs(dateEnd).format('D')} декабря</p>
            :  <p className="window__main window__text window__text--bold">Этот сюрприз более не доступен :(</p>
            }

        </div>
  } else if (isStatusWithClass === 'active'){
    popupContent =
        windowIndex === 30 ?
              <div className="window__popup-wrapper">
                <p className="window__main window__text window__text--bold">{description}</p>
                <p className="window__date window__text window__text--bold">{formattedDate}</p>

                {textBottom && <p dangerouslySetInnerHTML={textBottom ? {__html: textBottom} : undefined} className="window__sign window__text window__text--sign"/>}
                <p dangerouslySetInnerHTML={notice ? {__html: notice} : undefined} className="window__sign window__sign--link window__text window__text--sign"/>
              </div>
        :

              <div className="window__popup-wrapper">
                <p className="window__main window__text window__text--bold">{description}</p>
                <p className="window__sign window__text window__text--sign">{notice}</p>
                <p className="window__date window__text window__text--bold">{formattedDate}</p>

                {textBottom && <p dangerouslySetInnerHTML={textBottom ? {__html: textBottom} : undefined} className="window__sign window__text window__text--sign"/>}
              </div>
  } else if (isStatusWithClass === 'soon'){
    popupContent =
        <div className="window__popup-wrapper">
          <p className="window__main window__text window__text--bold">Ещё немного терпения!</p>
          <p className="window__sign window__text window__text--sign">Этот сюрприз можно будет открыть {dayjs(dateOpen).format('D')} декабря </p>
        </div>
  }



  const turnOn = ()=>{
    if (window.innerWidth > 1024) setVisibility(true);
  }
  const turnOff = ()=>{
    if (window.innerWidth > 1024) setVisibility(false);
  }
  const showFixedPopup = ()=> {
    if (isMobileT) {
      onInteractive(true);
      onPopupOpened(popupContent);
      setVisibilityOnMobile(!isMobileVisible);
    }
  }

  let finalLayout = windowIndex !== 30 ?
      <div
          className={`calendar__window window window--${windowIndex} ${isStatusWithClass}`}
          onMouseEnter={turnOn}
          onMouseLeave={turnOff}
          onClick={showFixedPopup}>
        {!isMobileT &&
        <div className={`window__popup ${isVisible ? 'visible' : ''}`}>
          {popupContent}
        </div>
        }
        <div className="window__content">
          <div className="window__image">
            {getFinalIcon(category)}
          </div>
          <div className="window__index">{windowIndex + 1}</div>
        </div>
      </div>
      :
      <div
          className={`calendar__window window window--link window--${windowIndex} ${isStatusWithClass}`}
          onMouseEnter={turnOn}
          onMouseLeave={turnOff}
          onClick={showFixedPopup}>
        <LinkDefault
            className="window__content-link"
            to={'https://online.lenta.com/2022/'}
            gtmLabelName={gtmLabelName}
        >
          {!isMobileT &&
          <div className={`window__popup ${isVisible ? 'visible' : ''}`}>
            {popupContent}
          </div>
          }
          <div className="window__content">
            <div className="window__image">
              {getFinalIcon(category)}
            </div>
            <div className="window__index">{windowIndex + 1}</div>
          </div>
        </LinkDefault>
      </div>

  return (
      finalLayout
  );
};
