import React from 'react';
import {LinkDefault} from './LinkDefault';

type Props = React.PropsWithChildren<{
  to: string;
  gtmLabelName: string;
  className: string;
}>

export const LinkPDF = ({to, gtmLabelName, children, className}: Props) => {
  return <LinkDefault to={to}
                      gtmAction='clickElement'
                      gtmLabelName={`${children} | ${gtmLabelName}`}
                      withoutText
  className={className}
  ><span>{children}</span></LinkDefault>
}