import {createAsyncThunk} from "@reduxjs/toolkit";
import {http} from 'a-utils';
import {Crazy, State} from 'TYPES/State';
// import {getParameterByName} from "../../components/utils/getParameterByName";

export const getCrazyBanners = createAsyncThunk<Crazy[], void, {state: State}>(
    'blocks/crazy-link',
    async (arg, thunkAPI) => {
        const {api} = thunkAPI.getState().options;
        const {rejectWithValue} = thunkAPI;

        // const urlSearchParams = new URLSearchParams(window.location.search);
        // const params = Object.fromEntries(urlSearchParams.entries());
        // let returnedValue = params.day;
        //
        // if (!returnedValue){
        //     returnedValue = '17/';
        // }

        try {
            return await http<Crazy[]>({
                action: api + `banner-crazy/`
            });
        } catch (error) {
            return rejectWithValue({});
        }
    });