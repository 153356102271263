import './PropductCard.scss';
import './product-special.scss';

import React from 'react';
import {Product} from 'TYPES/State';
import {LinkDefault} from '../links/LinkDefault';


type Props = Product & {
  className?: string;
  gtmLabelName: string;
};

export const ProductCard = ({top, price, balls, label, name, prefix, suffix, image, notice, isShield, isBig, isRed, url, gtmLabelName, className, specialType}: Props) => {
  let returnedPrice;
  if (price) {
    returnedPrice = <div className="product-card-price">
      <div className="product-card-price-new">
        {prefix && <span className="product-card-price-prefix">{prefix}</span>}
        {price.new.split('.').map((p, i) => <span className={'product-card-price-' + i} key={'pr-' + i}>{p}</span>)}
        {suffix && <span className="product-card-price-suffix">{suffix}</span>}
      </div>
      {price.old && <div className="product-card-price-old"><span>{price.old}</span></div>}
      {balls && <div className="product-card-price-balls"><span>{balls}</span></div>}
    </div>
  }

  let c = 'product-card';
  if (isBig) c = `${c} full-top`;
  if (isRed) c = `${c} red-top`;
  if (className) c = `${c} ${className}`;

  let returned;
  if (specialType){
    returned =
        <>
          <div className="product-special">
            <div className="product-special__image">
              <img src={require('./images/Card@2x.png')} alt=""/>
            </div>
            <div className="product-special__sign">Лучший подарок!</div>
          </div>
        </>;
  } else {
    returned = <>
      {/*{isShield && <div className="product-card-shield"/>}*/}
      <div className="product-card-top">
        {top && <div className="product-card-yellow">
          {prefix && <span className="product-card-top-prefix">{prefix}</span>}
          {top}
          {suffix && <span className="product-card-top-suffix">{suffix}</span>}
        </div>}
        {returnedPrice}
      </div>
      <div className="product-card-image">
        <div className="product-card__image-wrap"><img src={image} alt=""/></div>
      </div>
      <div className="product-card-name">
        {label && <span className="product-card-name-label">{label}</span>}
        {name}
      </div>
      <div className="product-card-notice">{notice}</div>
    </>;
  }


  if (url) {
    let gtmLabel = name;
    gtmLabel = `${name} | ${gtmLabelName}`;

    return <LinkDefault className={c}
                        to={url}
                        withoutText
                        gtmLabelName={gtmLabel}>
      {returned}
    </LinkDefault>
  } else {
    return <div className={c}>
      {returned}
    </div>;
  }
};