import '../Field.scss';
import './Select.scss';

import React, {useEffect, useState} from 'react';
import {isTouch} from 'a-utils';
import useGlobalDOMEvents from 'HOOKS/useGlobalDOMEvents';
import getClass from '../getClass';

const IS_TOUCH = isTouch();


type T_Props = {
  className?: string;
  label?: string;
  name?: string;
  error?: boolean;
  disabled?: boolean;
  notice?: string | boolean;
  items: {id: string; name: React.ReactNode;}[];
  value?: string[];
  defaultValue?: string[];
  multiple?: boolean;
  onChange?: (value: string[], isComplete: boolean, name?: string) => void;
}
type T_PropsItem = {
  children?: React.ReactNode;
  selected?: boolean;
  onClick: () => void;
}


const SelectItem = ({children = '', selected = false, onClick}: T_PropsItem) => {
  return <button type='button'
                 className={`select-item${selected ? ' selected' : ''}`}
                 onClick={onClick}
                 onKeyDown={(e) => e.key.toLowerCase() === 'enter' && onClick()}
  >
    {children}
  </button>
}

export const Select = ({className, items, value = [], ...props}: T_Props) => {
  const [selectedValues, setSelectedValues] = useState(props.defaultValue || value);
  // const [isNative, setIsNative] = useState(IS_TOUCH);
  const [isOpen, setIsOpen] = useState(false);
  const [customClassName] = useState('select-wrapper-' + Math.round(Math.random() * 1000));


  /**
   * Рассчитываем ширину экрана для использования
   * нативного или кастомного селекта
   * Так же обработка пустого клика на документ
   */
  useGlobalDOMEvents({
    // resize() {
    //   // if (window.innerWidth < 860) {
    //   //   setIsNative(true);
    //   // } else {
    //   //   setIsNative(false);
    //   // }
    // },
    click(e) {
      const target = e.target as HTMLElement;

      if (!target.closest('.' + customClassName)) {
        setIsOpen(false);
      }
    },
    keydown(e) {
      if (e.code.toLowerCase() === 'escape') {
        setIsOpen(false);
      }
    }
  })

  /**
   * Обновление данных с пропсов
   */
  useEffect(() => {
    if (!value || !value.length) return;

    if (props.multiple) {
      setSelectedValues(value);
    } else {
      setSelectedValues([value[0]]);
    }
  }, [value]);


  const htmlValue = items.map((item) => {
    return selectedValues.includes(item.id) && item.name;
  }).filter(Boolean);
  let returned;
  let tabindex;

  // if (isNative) {
  //   tabindex = -1;
  //
  //   const options = items.map((item, index) => {
  //     return <option key={'select-item_' + index}
  //                    value={item.id}>{item.name}</option>
  //   });
  //   if (options.length < 2) {
  //     options.unshift(<option selected disabled>Please select</option>)
  //   }
  //
  //   returned = <select multiple={props.multiple}
  //                      disabled={props.disabled}
  //                      value={props.multiple ? selectedValues : selectedValues[0]}
  //                      className='select-native'
  //                      onChange={(e) => {
  //                        const newSelected = Array.from<HTMLOptionElement>(e.target.selectedOptions).map((option) => {
  //                          return option.value;
  //                        })
  //                        setSelectedValues(newSelected);
  //
  //                        if (props.onChange) props.onChange(newSelected, !!newSelected.length, props.name);
  //                      }}>
  //     {options}
  //   </select>
  // } else {
    const check = (id: string) => {
      const index = selectedValues.indexOf(id);
      let newSelected: string[];

      if (index > -1) {
        if (props.multiple) {
          selectedValues.splice(selectedValues.indexOf(id), 1);
          newSelected = [...selectedValues];
        } else {
          newSelected = [id];
          setIsOpen(false);
        }
      } else {
        if (props.multiple) {
          newSelected = [...selectedValues, id];
        } else {
          newSelected = [id];
          setIsOpen(false);
        }
      }
      setSelectedValues(newSelected);

      if (props.onChange) props.onChange(newSelected, !!newSelected.length, props.name);
    }

    returned = <div className='select-items'>
      {items.map((item, index) => {
        const selected = selectedValues.includes(item.id);
        const sProps = {
          key: 'select-item_' + index,
          selected,
          onClick: () => check(item.id)
        }

        return <SelectItem {...sProps}>{item.name}</SelectItem>
      })}
    </div>
  // }

  let cn = getClass('field-wrapper select-wrapper ' + customClassName, {
    notice: !!props.notice,
    error: props.error
  });
  if (isOpen) cn += ' active';
  if (className) cn = `${cn} ${className}`;

  return <div className={cn}>
    <span className='field-label'>{props.label}</span>
    <button type='button' disabled={props.disabled} className='select-button' tabIndex={tabindex} onClick={() => {
      setIsOpen(!isOpen)
    // }} title={htmlValue}>
    }}>
      <span className="select-values">{htmlValue}</span>
    </button>

    {returned}
    <span className='field-notice'>{props.notice}</span>
  </div>
}