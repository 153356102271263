import React, {useEffect, useRef, useState} from 'react';
import ResizeStore, {ResizeData} from '../utils/resize/resize.store';
import ScrollTo from '../utils/scroll-to';
import GTM from '../ui/google/GTM';
import {Modules} from 'TYPES/State';
import {useGTMLabel} from 'HOOKS/useGTMLabel';
import {useDispatch, useSelector} from 'STORE/store';
import {setActive} from 'STORE/slices/Anchors';


type Props = React.PropsWithChildren<{
  module: Modules;
}>;


export const Anchor = ({module, children}: Props) => {
  const [div, setDiv] = useState<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const activeAnchorFromStore = useSelector(state => state.anchors.active);
  const gtmSetted = useRef(false);
  const updating = useRef(false);
  const pos = useRef(0);
  const label = useGTMLabel(module);

  useEffect(() => {
    if (!div) return;

    let vh = 1;
    let size = {
      top: 0,
      bottom: 1,
      height: 1,
      twenty: 1
    }

    const onUpdate = (event: 'start' | 'complete') => {
      if (event === 'start') {
        updating.current = true
      } else if (event === 'complete') {
        updating.current = false;
        onScroll();
      }
    }

    const getTop = () => window.scrollY;
    const onScroll = () => {
      if (updating.current) return;

      const top = getTop();

      if (top + vh - size.twenty > size.top && top < size.bottom - size.twenty) {
        if (!gtmSetted.current) {
            GTM.push({
              action: 'openAnchor',
              label,
              non_interaction: true
            })
          gtmSetted.current = true;
        }
      }
    }
    const onResize = (e: ResizeData) => {
      vh = e.height;

      const top = getTop();
      const bounds = div.getBoundingClientRect();

      size.height = bounds.height;
      size.top = top + bounds.top;
      size.bottom = size.height + size.top;
      size.twenty = size.height * 0.2;

      pos.current = size.top;

      onScroll();
    }

    onResize(ResizeStore.getData());

    ResizeStore.eventEmitter.addListener(onResize);
    window.addEventListener('scroll', onScroll);
    ScrollTo.addListener(onUpdate);

    return () => {
      ResizeStore.eventEmitter.removeListener(onResize);
      window.removeEventListener('scroll', onScroll);
      ScrollTo.removeListener(onUpdate);
    }
  }, [div]);

  useEffect(() => {
    if (activeAnchorFromStore !== module) return;

    dispatch(setActive());
    ScrollTo.toPos(pos.current);
  }, [activeAnchorFromStore, module]);

  return <div className='anchor' style={{position: 'relative'}} ref={setDiv} id={module}>
    {children}
  </div>
}