import dayjs from "dayjs";
require('dayjs/locale/ru')
dayjs.locale('ru')

import React, {useEffect, useState} from 'react';
import useGlobalDOMEvents from "HOOKS/useGlobalDOMEvents";
import {ActionsCard} from "./ActionsCard";
import {Slider} from "../slider/Slider";

export const ActionsDay = ({day, items, clickPopupHandler, gtmLabelName}) => {
  const [isMobileT, setIsMobileT] = useState(window.innerWidth < 1025);
  const [newDay, setNewDay] = useState(0);
  const [dayOfTheWeek, setNewDayOfTheWeek] = useState(0);

  useEffect(()=>{
    let tempDay;
    let dateParts = day.split(".");
    let dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);dateObject.toString();
    tempDay = dateObject.toString();

    setNewDay(dayjs(tempDay).format('DD.MM'));
    setNewDayOfTheWeek(dayjs(tempDay).format('dddd'));

  }, [])

  useGlobalDOMEvents({
    'resize': () => {
      setIsMobileT(window.innerWidth < 1025)
    }
  });

  return (
      <div className="actions__list-holder">
        <div className="actions__title">
          {day && <div className="actions__title-numbers">{newDay}</div>}
          <div className="actions__title-letters">{dayOfTheWeek}</div>
        </div>

        {isMobileT && items.length > 1 ?
          <Slider
              gtmLabelName={'actions'}
              loop
              className='four catalog-slider slider--white-back'
              sliderType='products-slider'
              spaceBetween={20}
          >
            {items?.map((s, i) => <ActionsCard {...s} gtmLabelName={gtmLabelName} key={'actions-slider-' + i} cardIndex={i} day={`${dayOfTheWeek} ${newDay}`} clickHandler={clickPopupHandler}/>)}
          </Slider>
            :
            <ul className="actions__list">
              {items?.map((s, i) => <ActionsCard {...s} gtmLabelName={gtmLabelName} key={'actions-item-' + i} cardIndex={i} day={`${dayOfTheWeek} ${newDay}`} clickHandler={clickPopupHandler}/>)}
            </ul>
        }
      </div>
  );
};
