import {createAsyncThunk} from "@reduxjs/toolkit";
import {http} from 'a-utils';
import {Filter, State} from 'TYPES/State';

export const getFilters = createAsyncThunk<Filter[], void, {state: State}>(
    'blocks/g-filter',
    async (arg, thunkAPI) => {
        const {api} = thunkAPI.getState().options;
        const {rejectWithValue} = thunkAPI;

        try {
            return await http<Filter[]>({
                action: api + 'catalog-filters/'
            });
        } catch (error) {
            return rejectWithValue({});
        }
    });