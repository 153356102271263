export const getSlidesPerView = () => {
  if (window.innerWidth < 568) {
    return 'auto';
  } else if (window.innerWidth >= 568 && window.innerWidth < 960) {
    return 2;
  } else if (window.innerWidth >= 960 && window.innerWidth < 1200) {
    return 3;
  } else {
    return 4;
  }
}