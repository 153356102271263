import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {City, Geo} from 'TYPES/State';
import {getCities} from 'STORE/thunks/cities-thunk';
import {getMapItems} from 'STORE/thunks/map-thunk';


const initialOptionsState: Geo = {
  mapItems: [],
  activeCity: {
    name: '',
    id: null
  },
  mainCities: [],
  citiesGroups: [],
  citiesLoaded: false,
  citiesPopupActive: false
};

const geoSlice = createSlice({
  name: 'slice-reducer',
  reducers: {
    setActiveCity: (state: Geo, action: PayloadAction<City>) => {
      state.activeCity = action.payload;
      state.mapItems = [];
    },
    setCitiesPopupActive: (state: Geo, action: PayloadAction<boolean>) => {
      state.citiesPopupActive = action.payload;
    }
  },
  // async reducers
  extraReducers: (builder) => {
    builder.addCase(getCities.pending, (state, action) => {
      console.log('loading cities');
    })
    builder.addCase(getCities.fulfilled, (state: Geo, action) => {
      const {activeCity, main, all} = action.payload;
      state.activeCity = activeCity;
      state.mainCities = main;
      state.citiesGroups = all;
      state.citiesLoaded = true;
    })
    builder.addCase(getCities.rejected, (state: Geo, action) => {
      console.log(action);
    })

    builder.addCase(getMapItems.pending, (state, action) => {
      console.log('loading map items');
    })
    builder.addCase(getMapItems.fulfilled, (state: Geo, action) => {
      state.mapItems = action.payload;
    })
    builder.addCase(getMapItems.rejected, (state: Geo, action) => {
      console.log(action);
    })
  },
  initialState: initialOptionsState
});

export const {setActiveCity, setCitiesPopupActive} = geoSlice.actions;
export default geoSlice;