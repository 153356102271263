import './gifts.scss'

import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Slider} from "../slider/Slider";
import {LinkPDF} from "../links/LinkPDF";
import {LinkDefault} from "../links/LinkDefault";
import {ProductCard} from "../product-card/ProductCard";
import {getAnchor, useGTMLabel} from "HOOKS/useGTMLabel";
import {useSelector} from "STORE/store";
import GTM from "../ui/google/GTM";
import Delayed from "../utils/Delayed";

export const GiftsModule = ({module}) => {
    const label = useGTMLabel(module);
    const [isMobile, setItMobile] = useState(window.innerWidth < 768)
    const catalog = useSelector(state => state.blocks.gifts);

    const onResize = () =>{
      setItMobile(window.innerWidth < 768);
    }

    useEffect(()=>{
      window.addEventListener('resize', onResize);
    }, [])

    let anotherCard = {
      url: 'https://lenta.com/gift-cards/',
      name: 'asd',
      image: 'undefined',
      specialType: true,
    }
    let arrayWithGiftCard = [anotherCard].concat(catalog);

    let mobileContent =
        <div className="gifts__mobile-banner">
          <img src={require(`./images/mobile-visual@2x.png`)} alt=""/>
        </div>;

    let slidesPerView;
    if (window.innerWidth < 568){
        slidesPerView = 'auto';
    } else if (window.innerWidth <= 568 && window.innerWidth < 960){
        slidesPerView = 2
    } else if (window.innerWidth <= 960 && window.innerWidth < 1200) {
        slidesPerView = 3;
    } else {
        slidesPerView = 4;
    }

    const gtmClickHandler = (e) =>{
      GTM.push({
        action: 'clickElement',
        label: `${e.target.textContent || 'Нет текста в кнопке'} | ${label}`
      });
    }


    return (
        <div className="gifts">
            <div className="gifts__wrapper">
                <div className="gifts__content">
                    <div className="heading heading--gifts heading--gradient wrapper">
                        <h2 className="h2">
                            <span className="gradient">Новогодние<br/>подарки</span>
                        </h2>
                        <p className="heading__sign sign gradient">под ёлку и не только</p>
                    </div>


                    <div className="gifts__slider">
                      <Delayed>
                        <Slider
                            loop={true}
                            gtmLabelName={label}
                            className='four catalog-slider catalog-slider--trees slider--blue-back'
                            sliderType='products-slider'
                            slidesPerView={slidesPerView}
                            spaceBetween={window.innerWidth > 1024 ? 40 : 20}
                            bottomContent={
                                <div>
                                    <div className='slider__buttons'>
                                        <LinkDefault gtmLabelName={label}
                                                     to={'https://catalogs.lenta.com/hyper/ADDAR-2021-hmc?version=V1&page=2'}
                                                     className="button button--transparent-white">Смотреть весь каталог</LinkDefault>
                                        <LinkDefault to={'https://catalogs.lenta.com/hyper/ADDAR-2021-hmc?version=V1&page=1'}
                                                     gtmLabelName={label}
                                                     className='button button--white'>Смотреть все подарки</LinkDefault>
                                    </div>
                                </div>
                            }
                        >
                          {arrayWithGiftCard?.map((s, i) => <ProductCard {...s} gtmLabelName={label} key={'pc-' + i}/>)}
                        </Slider>
                      </Delayed>
                    </div>

                  <div className="gifts__banner">
                    <div className="gifts__banner-wrapper wrapper">

                      {window.innerWidth > 1024 && <div className="gifts__banner-back"/>}
                      <div className="gifts__banner-content">
                        {isMobile && mobileContent}

                        {window.innerWidth > 1024 &&
                        <div className="gifts__banner-decorations">
                          <div className="gifts__banner-front"/>
                        </div>
                        }
                        <div className="gifts__heading heading heading--inside-banner heading--blue wrapper">
                          <div className="heading__titles">
                            <h2 className="h2"><span>Выбираем</span></h2>
                            <h2 className="h2"><span>новогодние</span></h2>
                            <h2 className="h2"><span>подарки</span></h2>
                          </div>
                          <p className="heading__sign sign">Найти идеальный вариант<br/>поможет наш конструктор</p>
                        </div>
                        <div className="gifts__redirect">
                          <Link className="button button--blue" to="/gifts" onClick={gtmClickHandler}>
                            <span>Выбрать</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
            </div>
        </div>
    );
};
