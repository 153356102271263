import './catalog.scss';

import React from 'react';
import {useGTMLabel} from "HOOKS/useGTMLabel";
import {useSelector} from "STORE/store";

import {Slider} from '../slider/Slider'
import {LinkPDF} from "../links/LinkPDF";
import {LinkDefault} from "../links/LinkDefault";
import {ProductCard} from '../product-card/ProductCard';
import {CollectionItem} from "../collection-item/CollectionItem";

const promotionData = {
  url: "https://lenta.com/podborka/decorations/"
}

const collection = [
  {
    name: `Ёлки`,
    sign: `<span>Ёлки</span>`,
    image: require('./images/col1.png'),
    url: 'https://lenta.com/podborka/elki/'
  },
  {
    name: `Декор для дома`,
    sign: `<span>Декор <br>для дома</span>`,
    image: require('./images/col2@2x.png'),
    url: 'https://lenta.com/podborka/home_decor/'
  },
  {
    name:  `Сервировка стола`,
    sign:  `<span>Сервировка <br>стола</span>`,
    image: require('./images/col3@2x.png'),
    url: 'https://lenta.com/podborka/serving/'
  },
  {
    name: `Новогодние украшения`,
    sign: `<span>Новогодние <br>украшения</span>`,
    image: require('./images/col4@2x.png'),
    url: 'https://lenta.com/podborka/ny_decoration/'
  },
  {
    name:  `Постельное бельё`,
    sign:  `<span>Постельное<br>бельё</span>`,
    image: require('./images/col5@2x.png'),
    url: 'https://lenta.com/podborka/bed_linen/'
  }
]

export const Catalog = ({module}) => {
  const label = useGTMLabel(module);
  const catalog = useSelector(state => state.blocks.catalog);

  return (
      <div className="section section--white catalog">
          <div className="catalog__wrapper">
            <div className="catalog__content">

              <div className="catalog__heading heading heading--blue wrapper">
                <div className="heading__titles">
                  <h2 className="h2"><span>Всё для</span></h2>
                  <h2 className="h2"><span>праздника</span></h2>
                </div>
                <p className="heading__sign sign">в одном месте</p>
              </div>

              <div className="catalog__slider">

                <Slider
                    gtmLabelName={label}
                    loop
                    className='four catalog-slider slider--white-back'
                    sliderType='products-slider'
                    spaceBetween={window.innerWidth > 1024 ? 40 : 20}
                    bottomContent={
                      <div>
                        <div className="slider__additional-sign sign">Новые товары каждую неделю!</div>
                        <div className='slider__buttons'>
                          <LinkPDF gtmLabelName={label} to={'https://2022.lenta.com/tmp/Katalog_HM_Dom_gde_givut_chudesa.pdf'} className="button button--blue">
                            Смотреть весь каталог
                          </LinkPDF>
                          <LinkDefault to={promotionData.url}
                                       gtmLabelName={label}
                                       className='button button--transparent'>Смотреть все товары</LinkDefault>
                        </div>
                      </div>
                    }
                >
                  {catalog?.map((s, i) => <ProductCard {...s} gtmLabelName={label} key={'pc-' + i}/>)}
                </Slider>
              </div>

              <div className="catalog__collections collection">
                <div className="collection__wrapper wrapper">
                  <ul className="collection__list">
                    {collection.map((s, i) => <CollectionItem {...s} gtmLabelName={label} key={'ci-' + i}/>)}
                  </ul>
                </div>
              </div>

            </div>
        </div>
      </div>
  );
};
