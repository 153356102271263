import './promoSnowFall.scss';
import React, {useEffect} from 'react';

export const PromoSnowfall = () => {
  const SNOWFLAKES_COUNTER_1 = 10;
  const SNOWFLAKES_COUNTER_2 = 50;
  const SNOWFLAKE_MAX_SIZE = 3;
  const SNOWFLAKE_MIN_SIZE = 8;

  const getRandomIntInclusive = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  const generateRandomSize = (min, max) =>{
    let size = getRandomIntInclusive(min,max);
    let left = getRandomIntInclusive(1, 95);
    let blur = getRandomIntInclusive(1,2);
    let animationTime = getRandomIntInclusive(8,20);
    return {
      width: size,
      height: size,
      filter: `blur(${blur}px)`,
      left: `${left}%`,
      animationDuration: `${animationTime}s`
    }
  }

  const snowFlakeArray = new Array(SNOWFLAKES_COUNTER_1).fill('');
  const snowFlakeArray2 = new Array(SNOWFLAKES_COUNTER_2).fill('');

  let snowflakes = snowFlakeArray.map((flake, index)=>{
    return <div className="snowflake" key={`snowflake-${index}`} style={{left: `${getRandomIntInclusive(1, 100)}%`, animationDuration: `${getRandomIntInclusive(10,20)}s, 5s`}}>❅</div>
  })

  let snowflakes2 = snowFlakeArray2.map((flake, index)=>{
    return <div className="snowflake--2" key={`snowflake2-${index}`} style={generateRandomSize(SNOWFLAKE_MIN_SIZE,SNOWFLAKE_MAX_SIZE)}/>
  })

  return (
      <div className="promo__snowfall snowfall">
        {snowflakes}
        {snowflakes2}
      </div>
  );
};
