import React from 'react';
import {connect} from 'react-redux';
import {Modules, State, ServiceKeys} from 'TYPES/State';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {getModules} from 'STORE/thunks/modules-thunk';
import {getServiceKeys} from 'STORE/thunks/keys-thunk';
import {getCities} from 'STORE/thunks/cities-thunk';
// import {getPdfs} from 'STORE/thunks/pdfs-thunk';
import GTM from './ui/google/GTM';

import {CitiesPopup} from './cities-popup/CitiesPopup';
import {Header} from './header/Header';
import {SubmitPopup} from './submit-popup/SubmitPopup';
import {ConfirmPopup} from './confirm-popup/ConfirmPopup';
import {Anchor} from './anchor/Anchor';
import router from './router';
import {getCatalog} from "STORE/thunks/catalog-thunk";
import {getSolutions} from "STORE/thunks/solutions-thunk";
import {getFilters} from "STORE/thunks/g-filters-thunk";
import {getGifts} from "STORE/thunks/gifts-thunk";
import {getCalendar} from "STORE/thunks/calendar-thunk";
import {getCrazyBanners} from "STORE/thunks/crazy-thunk";

import RootRouter from '../router/RootRouter';
import ScrollToTop from "./scroll-top/ScrollToTop";
import {getActions} from "STORE/thunks/actions-thunk";

type AppProps = {
    modules: Modules[];
    keys: ServiceKeys | null;
    getModules: () => void;
    getKeys: () => void;
    getCities: () => void;
    getGifts: () => void;
    // getPdfs: () => void;
    getCatalog: () => void;
    getSolutions: () => void;
    getFilters: () => void;
    getCalendar: () => void;
    getCrazy: () => void;
    getActions: () => void;
}


// класс потому, что react-refresh-webpack-plugin
// не работает в руте с чистой функцией

class App extends React.Component<AppProps, {}> {
    componentDidMount() {
        GTM.create();
        this.props.getModules();
        this.props.getKeys();
        this.props.getCities();
        // this.props.getPdfs();
        this.props.getGifts();
        this.props.getCatalog();
        this.props.getSolutions();
        this.props.getFilters();
        this.props.getCalendar();
        this.props.getCrazy();
        this.props.getActions();
    }
    componentDidUpdate(prevProps: Readonly<AppProps>, prevState: Readonly<{}>, snapshot?: any) {
        if (this.props.keys && this.props.keys.GTM) {
            GTM.initialize('GTM-' + this.props.keys.GTM);
            // GTM.initialize('GTM-MNJ3WR9');
        }
    }
    render() {
        return (
            <>
                <BrowserRouter>
                    <Header/>
                    <CitiesPopup/>
                    {/*<SubmitPopup/>*/}
                    {/*<ConfirmPopup/>*/}

                    <ScrollToTop />
                    <RootRouter modules={this.props.modules}/>
                </BrowserRouter>
            </>
        );
    }
}


// TODO: Сделать по-человечески типы
export default connect(
    (state: State) => ({
        modules: state.blocks.modules,
        keys: state.options.keys
    }),
    (dispatch: any) => ({
        getModules: () => dispatch(getModules()),
        getGifts: () => dispatch(getGifts()),
        getCatalog: () => dispatch(getCatalog()),
        getSolutions: () => dispatch(getSolutions()),
        getFilters: () => dispatch(getFilters()),
        getCalendar: () => dispatch(getCalendar()),
        getKeys: () => dispatch(getServiceKeys()),
        getCities: () => dispatch(getCities()),
        getCrazy: () => dispatch(getCrazyBanners()),
        getActions: () => dispatch(getActions()),
        // getPdfs: () => dispatch(getPdfs()),
    })
)(App);
