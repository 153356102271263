import React, {useEffect, useState} from 'react';
import {LinkDefault} from "../../links/LinkDefault";

export const StarButton = (props, ) => {
  const {dataIndex, activeStar, toggleStar} = props;
  const isStarOpen = dataIndex === activeStar;
  let openedTimeout;
  const fields = {
    starIndex: props.dataIndex,
    imageRef: props.itemImage,
    left: props.starPosLeft,
    top: props.starPosTop,
    link: props.itemLink,
    text: props.itemDescription,

  }
  const starPosition = {
    top: fields.top + '%',
    left: fields.left + '%'
  }
  const starPopupImage = <div className="slider-card__preview">
        <img src={fields.imageRef} alt=""/>
      </div>;
  const starPopupText = <div className="slider-card__text">
        <p>{fields.text}</p>
      </div>;
  const starPopupArrow = <div className="slider-card__icon">
        <svg width="16" height="17" viewBox="0 0 16 17">
          <path  d="M5.29289 3.12619C5.68342 2.73566 6.31658 2.73566 6.70711 3.12619L11.3738 7.79285C11.5613 7.98039 11.6667 8.23474 11.6667 8.49996C11.6667 8.76518 11.5613 9.01953 11.3738 9.20707L6.70711 13.8737C6.31658 14.2643 5.68342 14.2643 5.29289 13.8737C4.90237 13.4832 4.90237 12.85 5.29289 12.4595L9.25245 8.49996L5.29289 4.5404C4.90237 4.14988 4.90237 3.51671 5.29289 3.12619Z"/>
        </svg>
      </div>

  const togglePopup = e => {
    if (window.innerWidth < 1025) return;
    e.stopPropagation();
    if (!isStarOpen) {
      toggleStar()
    }
  }

  const handleClickAside = (e) => {
    if (isStarOpen) {
      toggleStar()
    }
  }

  const onHover = (e) =>{
    if (openedTimeout){
      clearTimeout(openedTimeout);
    }
    togglePopup(e);
  }

  // const onLeave = (e) =>{
  //   e.stopPropagation();
  //     openedTimeout = setTimeout(() => {
  //       togglePopup(e);
  //   });
  // }

  useEffect(() => {
    window.addEventListener('click', handleClickAside)

    return () => window.removeEventListener('click', handleClickAside)
  }, [handleClickAside])

  return (
      <div style={starPosition} className={`slider-card__star ${isStarOpen ? 'opened' : ''}`}>
        <button className="slider-card__button" onClick={togglePopup} onMouseEnter={onHover}>
          <div className="slider-card__index">
            {fields.starIndex + 1}
          </div>
          <svg className="slider-card__star-icon" width="60" height="60" viewBox="0 0 60 60">
            <path className="back" opacity="0.48"
                  d="M36.4617 2.17164C37.0469 1.60092 38.0324 1.93522 38.1496 2.74418L40.5259 19.1493C40.7094 20.4156 41.4875 21.5177 42.6193 22.1143L57.2561 29.8301C57.9777 30.2105 57.9648 31.2483 57.234 31.6106L42.383 38.9732C41.2395 39.5402 40.4352 40.6187 40.218 41.8765L37.3947 58.2292C37.2558 59.034 36.2628 59.3418 35.6929 58.7567L24.1487 46.9031C23.2547 45.985 21.9749 45.5509 20.7068 45.7356L4.33324 48.1194C3.52499 48.2371 2.9242 47.3887 3.3036 46.6654L11.012 31.9697C11.6048 30.8394 11.6227 29.4941 11.0601 28.3484L3.75341 13.4699C3.39384 12.7377 4.01501 11.9062 4.81913 12.0433L21.1296 14.8249C22.3908 15.04 23.6789 14.6388 24.5949 13.7454L36.4617 2.17164Z"/>
            <path className="front"
                  d="M34.2213 9.08362C34.8064 8.5129 35.792 8.8472 35.9091 9.65615L37.5568 21.031C37.7402 22.2973 38.5184 23.3994 39.6502 23.996L49.7993 29.3461C50.5209 29.7265 50.508 30.7643 49.7771 31.1266L39.4767 36.2333C38.3332 36.8002 37.5289 37.8788 37.3117 39.1365L35.3536 50.4782C35.2146 51.2831 34.2216 51.5909 33.6517 51.0058L25.6486 42.7881C24.7545 41.8701 23.4748 41.436 22.2067 41.6206L10.8555 43.2733C10.0473 43.391 9.44649 42.5425 9.82588 41.8192L15.1722 31.6267C15.7651 30.4964 15.7829 29.1511 15.2203 28.0054L10.1525 17.6859C9.79294 16.9537 10.4141 16.1222 11.2182 16.2593L22.5278 18.1881C23.7891 18.4032 25.0771 18.0019 25.9931 17.1086L34.2213 9.08362Z"/>
          </svg>
        </button>
        <div className="slider-card__popup">
          <LinkDefault to={fields.link}
                       withoutText
                       className="slider-card__link"
                       gtmLabelName={`${fields.text} (${props.collection}) | ${props.gtmLabelName}`}>
            {starPopupImage}
            {starPopupText}
            {starPopupArrow}
          </LinkDefault>

        </div>
      </div>
  );
};
