type T_Props = {
  error?: boolean;
  notice?: boolean;
  focus?: boolean;
  value?: boolean;
}
type Keys = keyof T_Props;

const classes: {[key in Keys]: string} = {
  error: 'error',
  notice: 'notice',
  focus: 'focus',
  value: 'not-empty',
}

export default function getClass(initialClass: string, props: T_Props) {
  const c = Object.entries(props).map(([key, value]) =>
    value && classes[key as Keys]).filter(Boolean);
  return [initialClass, ...c].join(' ');
}