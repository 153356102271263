import './calendar-popup.scss'

import React from 'react';

export const CalendarPopup = ({className, content, onSelfClose}) => {
  const clickHandler = (e) => {
    let target = e.target;
    if (!target.closest('.calendar-popup__wrapper')){
      onSelfClose(false);
    }
  }
    return (
        <div className={className} onClick={clickHandler}>
            <div className="calendar-popup__wrapper">
              {content}
            </div>
            <div className="close-button"/>
        </div>
    );
};
