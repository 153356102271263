import './Popup.scss';

import React, {useEffect} from 'react';
import {Portal} from '../modal/Portal';
import GTM from '../google/GTM';

type T_Props = {
  children: React.ReactNode | null;
  active?: boolean;
  onClose?: () => void;
  className?: string;
  name: string;
}

export const Popup = (props: T_Props) => {
  useEffect(() => {
    if (props.active) {
      document.documentElement.style.overflow = 'hidden';
      GTM.push({
        action: 'popUpOpen',
        label: props.name,
        non_interaction: true
      });
    } else {
      document.documentElement.style.overflow = '';
    }

    return () => {
      document.documentElement.style.overflow = '';
    }
  }, [props.active]);

  const onClose = () => props.onClose && props.onClose();

  let className = 'popup';
  if (props.active) className += ' active';
  if (props.className) className = `${className} ${props.className}`;

  return <Portal className={className}>
    <div className='popup-self-close' onClick={onClose}/>
    <div className='popup-inner'>
      <button className='close-button' onClick={onClose}/>
      {props.children}
    </div>
  </Portal>
}