import './actions.scss';

import React, {useEffect, useState} from 'react';
import {ActionsDay} from "./ActionsDay";
import {ActionsPopup} from "../actions-popup/ActionsPopup";
import {useGTMLabel} from "HOOKS/useGTMLabel";
import {useSelector} from "STORE/store";
import useGlobalDOMEvents from "HOOKS/useGlobalDOMEvents";
import GTM from "../ui/google/GTM";

export const ActionsPage = ({module}) => {
  const label = useGTMLabel(module);
  const actionsData = useSelector(state => state.blocks.actions);
  const crazyData = useSelector(state => state.blocks.crazy);
  const [isMobileT, setIsMobileT] = useState(window.innerWidth < 1025);
  const [isVisible, setVisibility] = useState(false);
  const [isContentSet, setContent] = useState(null);

  let newArray = [];

  if (actionsData){
    for (let key in actionsData) {
      let dayItem = {
        day: key,
        items: actionsData[key]
      }
      newArray.push(dayItem)
    }
  }

  useGlobalDOMEvents({
    'resize': () => {
      setIsMobileT(window.innerWidth < 1025)
    }
  });

  useEffect(() => {
    if (isVisible) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = '';
    }
    return () => {
      document.documentElement.style.overflow = '';
    }
  }, [isVisible]);

  const onPopupOpen = (isOpened, content) =>{
    setVisibility(isOpened);
    setContent(content);
    let addLabel = `${content.day ? content.day : ''} | ${content.title ? content.title : ''} | ${label}`;
    GTM.push({
      action: 'popUpOpen',
      label: `${addLabel}`,
      non_interaction: true
    });
  }

  return (
      <div className="actions">
        {crazyData &&
        <div className="actions__temporary">
            {crazyData?.map((element, index)=> <div className="action__temp-banner"><img src={element.image} alt=""/></div>)}
        </div>}
        <div className="actions__content">
          <div className="actions__pendants">
            <div className="actions__p actions__p-card-m"><img src={require('./images/Card-m.svg')} alt="" /></div>
            <div className="actions__p actions__p-card"><img src={require('./images/Card.svg')} alt="" /></div>
            <div className="actions__p actions__p-star"><img src={require('./images/Star@2x.png')} alt="" /></div>
            <div className="actions__p actions__p-ball"><img src={require('./images/Ball@2x.png')} alt="" /></div>
            {!isMobileT && <div className="actions__p actions__p-cloud"><img width={662} height={247} src={require('./images/cloud1.svg')} alt="" /></div>}
          </div>
          <ActionsPopup
              className={isVisible ? 'actions-popup visible' : 'actions-popup'}
              onSelfClose={setVisibility}
              content={isContentSet}
          />
          <div className="actions__header">
            <div className="catalog__heading heading heading--h1 wrapper">
              <h1 className="actions__main-title heading__title h1">
                <span>Новогодняя </span>
                <span>распродажа </span>
              </h1>
            </div>

          </div>
          <div className="actions__body">
            {newArray?.map((element, index)=> <ActionsDay {...element} key={`actions-day-${index}`} gtmLabelName={label} clickPopupHandler={onPopupOpen}/>)}
          </div>
          <div className="actions__sign wrapper">
            <div className="actions__sign-wrapper ">
              <p className="actions__sign-title">Cкидки действуют по карте «Лента»</p>
              <p className="actions__sign-text">Расчёт скидки производится от цены без карты постоянного покупателя. Действуют оптовые ограничения. На товары, участвующие в других акциях, действуют специальные условия. Подробности о каждой позиции товара и цене на нее можно узнать на стойке информации или по телефону центра информационной поддержки клиентов 8-800-700-41-11 (звонок по России бесплатный).</p>
            </div>
          </div>
          <div className="actions__footer">
            {isMobileT ? <img src={require('./images/mobile@2x.png')} width={1105} height={342} alt=""/> : <img src={require("./images/il-bottom@2x.png")} width={3346} height={754}  alt=""/>}
          </div>
        </div>

      </div>
  );
};
