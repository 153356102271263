import './anchor-popup.scss';
import React, {useEffect, useState} from 'react';
import {AnchorButton} from "../anchor/AnchorButton";

export const AnchorPopup = () => {
  const [isVisible, setVisibleClass] = useState(false);
  const [isClose, setCloseBanner] = useState(false);

  const onClose = () => {
    setCloseBanner(true);
  }

  useEffect(()=>{
    window.addEventListener('load', ()=>{
      setTimeout(()=>{
        setVisibleClass(true);
      }, 1000)
    })
    return window.removeEventListener('load', ()=>{
      setTimeout(()=>{
        setVisibleClass(true);
      }, 1000)
    })
  }, [])


  return (
      <div className={`anchor-popup ${isVisible ? 'active' : ''} ${isClose ? 'destroy' : ''}`}>
        <div className="anchor-popup__wrapper">
          <div className="anchor-popup__close-button" onClick={onClose}/>
            <AnchorButton to='partners' gtmLabelName={'Клик по баннеру "Главный приз - квартира'} className="anchor-popup__content" onCloseHandler={onClose}>
              <div className="anchor-popup__button">
                <span>Подробнее</span>
              </div>
            </AnchorButton>
        </div>
      </div>
  );
};
