import dayjs from "dayjs";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {http} from 'a-utils';
import {Calendar, State} from 'TYPES/State';


export const getCalendar = createAsyncThunk<Calendar[], void, {state: State}>(
    'blocks/calendar',
    async (arg, thunkAPI) => {
        const {api} = thunkAPI.getState().options;
        const {rejectWithValue} = thunkAPI;
        // const todayDate = dayjs(new Date).format('YYYY-MM-DD');

        try {
            return await http<Calendar[]>({
                action: api + `calendar/`
                // action: api + `calendar/?now${todayDate}/`
                // action: api + `calendar/?now=2021-12-15`
            });
        } catch (error) {
            return rejectWithValue({});
        }
    });