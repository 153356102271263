import './calendar.scss';

import React, {useEffect, useState} from 'react';
import {CalendarWindow} from "../calendar-window/CalendarWindow";
import {Slider} from "../slider/Slider";
import {useGTMLabel} from "HOOKS/useGTMLabel";
import {useSelector} from "STORE/store";
import useGlobalDOMEvents from "HOOKS/useGlobalDOMEvents";
import {CalendarPopup} from "../calendar-popup/CalendarPopup";

export const Calendar = ({module}) => {
  const label = useGTMLabel(module);
  const calendarData = useSelector(state => state.blocks.calendar);
  const [isMobileT, setIsMobileT] = useState(window.innerWidth < 1025);
  const [isMobilePopupVisible, setMobilePopupVisibility] = useState(false);
  const [isCurrentPopupContent, setCurrentPopupContent] = useState('не заполнено ((');
  const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

  const GROUP_SIZE = 6;
  let resultContent;
  let desktopButtons;
  let mobileButtons;
  let slicedArrays = [];
  let activeGroup;

  useGlobalDOMEvents({
    'resize': () => {
      setIsMobileT(window.innerWidth < 1025)
    }
  });

  if (calendarData){
    activeGroup = Math.floor(calendarData.findIndex((el)=>{return el.dateStart}) / GROUP_SIZE);

    for (let i = 0; i < calendarData.length; i += GROUP_SIZE) {
      slicedArrays.push(calendarData.slice(i, i + GROUP_SIZE));
    }
    desktopButtons = calendarData.map((responseCard, index)=>{
      return <CalendarWindow
          {...responseCard}
          key={`snowflake-${index}`}
          windowIndex={index}
          gtmLabelName={label}
      />
    })
    mobileButtons =
        <Slider
            gtmLabelName={label}
            className='calendar__slider windows-slider'
            sliderType='windows-adapt'
            slidesPerView={1}
            spaceBetween={16}
            pagination={true}
            initialSlide={activeGroup}
        >
          {slicedArrays?.map((slicedArray, index) =>
              <div className="calendar__group" key={`cg-${index}`}>
                {slicedArray?.map((s, i) =>
                    <CalendarWindow
                        {...s}
                        key={`snowflake-${i}`}
                        windowIndex={index * GROUP_SIZE + i}
                        onInteractive={setMobilePopupVisibility}
                        onPopupOpened={setCurrentPopupContent}
                        gtmLabelName={label}
                    />
                )}
              </div>
          )}
        </Slider>
  }
  resultContent = isMobileT ? mobileButtons : desktopButtons;

  useEffect(() => {
    if (isMobilePopupVisible) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = '';
    }
    return () => {
      document.documentElement.style.overflow = '';
    }
  }, [isMobilePopupVisible]);


  return (
      <div className="calendar">
        {isMobileT && <CalendarPopup
            className={isMobilePopupVisible ? 'calendar-popup visible' : 'calendar-popup'}
            onSelfClose={setMobilePopupVisibility}
            content={isCurrentPopupContent}
        />}
        <div className="calendar__back-decor">
          <img className="cloud1" width={1026} height={383} src={require('./images/back-cloud1.svg')} alt=""/>
          <img className="cloud2" width={1026} height={383} src={require('./images/back-cloud2.svg')} alt=""/>
          <img className="clouds" src={require('./images/clouds-back@2x.png')} alt=""/>
          <img className="cloud3" width={907} height={339} src={require('./images/back-cloud3.svg')} alt=""/>
          <img className="snow1" width={405} height={159} src={require('./images/Snow@2x.png')} alt=""/>
          <img className="snow2" width={405} height={151} src={require('./images/Snow1@2x.png')} alt=""/>
        </div>
        <div className="calendar__wrapper">
          <div className="calendar__content">

            <div className="calendar__heading heading heading--gradient wrapper">
              <h2 className="h2"><span className="gradient">Календарь скидок</span></h2>
              <div className="heading__special">
                <p className="heading__sign sign sign--tiny gradient">Суперпредложения каждый день</p>
                <div className="calendar__instructions">
                  {isMobileT && <p className="calendar__instructions-text mobile">Нажмите на окошко, чтобы узнать о супер-скидках в "Ленте".<br/><span>Не забудьте взять Карту№1!</span></p>}
                  {!isMobileT && <p className="calendar__instructions-text desktop">Наведите курсор на окошко, чтобы узнать о супер-скидках в "Ленте".<br/><span>Не забудьте взять Карту№1!</span></p>}
                  <div className="calendar__instructions-img">
                    {/*<img src={require('./images/arrow.svg')} alt=""/>*/}
                    <svg className={isChrome ? 'animated' : ''} width="75" height="96" viewBox="0 0 75 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path className="p1" d="M10.2502 89.8753C34 73.5 56.5 47.5 60 13.5" stroke="#FFB138" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="100" strokeDashoffset="0"/>
                      <path className="p2" d="M10.251 89.8753C15.1285 80.5 17.9899 75 18.6074 73.8131L10.251 89.8753Z" stroke="#FFB138" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="100" strokeDashoffset="0"/>
                      <path className="p3"  d="M10.2491 89.8753L27.6055 87.9375" stroke="#FFB138" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="50" strokeDashoffset="0"/>
                    </svg>


                  </div>
                </div>
              </div>
            </div>

            <div className="calendar__house">
              <div className="calendar__roof">
                {isMobileT ? <img src={require('./images/small-roof@2x.png')} alt=""/> : <img className="back" src={require('./images/roof-front@2x.png')} alt=""/>}
              </div>
              <div className="calendar__buttons">
                {resultContent}
              </div>
              <div className="calendar__decorations">
                <div className="calendar__d calendar__d--elca"><img src={require('./images/elca@2x.png')} alt=""/></div>
                <div className="calendar__d calendar__d--candy-l"><img src={require('./images/candy-l@2x.png')} alt="" /></div>
                <div className="calendar__d calendar__d--candy-r"><img src={require('./images/candy-r@2x.png')} alt="" /></div>
                <div className="calendar__d calendar__d--gm-l"><img src={require('./images/gm-l@2x.png')} alt="" /></div>
                <div className="calendar__d calendar__d--gm-r"><img src={require('./images/gm-r@2x.png')} alt="" /></div>
              </div>
            </div>


          </div>
          <div className="calendar__above">
            <div className="calendar__a calendar__a--front"><img src={require('./images/clouds-front@2x.png')} alt=""/></div>
            <div className="calendar__a calendar__a--left"><img src={require('./images/clouds-left@2x.png')} alt=""/></div>
            <div className="calendar__a calendar__a--right"><img src={require('./images/clouds-right@2x.png')} alt=""/></div>
          </div>
        </div>

      </div>
  );
};
