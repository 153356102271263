import React from 'react';
import {Modules} from 'TYPES/State';
// import {Main} from './main/Main';
import {Banners} from './banners/Banners';
import {Promo} from './promo/Promo';
import {Solutions} from './solutions/Solutions';
import {Catalog} from './catalog/Catalog';
import {Partners} from './partners/Partners';
import {Map} from './map/Map';
import {Footer} from './footer/Footer';
import {MobileApps} from './mobile-apps/MobileApps';
import {GiftsModule} from "./gifts-module/GiftsModule";
import {GiftsConstructor} from "./gifts-constructor/GiftsConstructor";
import {Calendar} from "./calendar/Calendar";
import {MoominBanner} from "./moomin-banner/MoominBanner";
import {Battle} from "./battle/Battle";
import {Charity} from "./charity/Charity";
import {Music} from "./music/Music";
import {CrazyLink} from "./crazy-redirect/Crazy-Link";
import {ActionsPage} from "./actions-page/ActionsPage";
import {ActionsBanner} from "./actions-banner/ActionsBanner";

export type RouterModule = React.FunctionComponent<{module: Modules}>;

type RouterItem = {
  module: RouterModule;
  gtmLabelName: string;
  anchorIndex: number;
  navLink?: {
    name: string;
  }
  route: string | null;
}
type Router = {
  [key in Modules]: RouterItem;
}

const router: Router = {
  'promo': {
    module: Promo,
    anchorIndex: 0,
    gtmLabelName: 'Промоблок (главный экран)',
    route: 'promo',
  },
  'calendar': {
    module: Calendar,
    anchorIndex: 1,
    gtmLabelName: 'Календарь скидок',
    route: 'calendar',
    navLink: {
      name: '<span class="h5">Календарь скидок</span>'
    }
  },
  'crazy-link': {
    module: CrazyLink,
    anchorIndex: 2,
    gtmLabelName: 'Crazy',
    route: null
  },
  'gifts': {
    module: GiftsModule,
    anchorIndex: 3,
    gtmLabelName: 'Выбираем новогодние подарки',
    route: 'gifts',
    navLink: {
      name: '<span class="h5">Выбираем подарки</span>'
    }
  },

  'solutions': {
    module: Solutions,
    anchorIndex: 4,
    gtmLabelName: 'Наряжаем ёлку',
    route: 'solutions',
    navLink: {
      name: '<span class="h5">Наряжаем ёлку</span>'
    }
  },
  'catalog': {
    module: Catalog,
    anchorIndex: 5,
    gtmLabelName: 'Всё для праздника',
    route: 'catalog',
    navLink: {
      name: '<span class="h5">Всё для праздника</span>'
    }
  },
  'moomin-banner': {
    module: MoominBanner,
    anchorIndex: 6,
    gtmLabelName: 'Время муми-трогательных подарков',
    route: 'moomin-banner',
  },
  'charity': {
    module: Charity,
    anchorIndex: 7,
    gtmLabelName: 'Баннер с благотворительностью',
    route: 'charity',
  },
  'music': {
    module: Music,
    anchorIndex: 8,
    gtmLabelName: 'Новогодний плейлист от Ленты',
    route: null,
  },
  'partners': {
    module: Partners,
    anchorIndex: 9,
    gtmLabelName: 'Получите скидки и подарки от наших друзей',
    route: 'partners',
    navLink: {
      name: '<span class="h5">Подарки от друзей </span>'
    }
  },
  'actions-banner': {
    module: ActionsBanner,
    anchorIndex: 9,
    gtmLabelName: 'Дарите подарочную карту «Лента»',
    route: null
  },
  'mobile-apps': {
    module: MobileApps,
    anchorIndex: 10,
    gtmLabelName: 'О, заказывай со скидками в Лента Онлайн!',
    route: 'mobile-apps',
    navLink: {
      name: '<span class="h5">Заказать Онлайн</span>'
    }
  },
  'cashback': {
    module: Battle,
    anchorIndex: 11,
    gtmLabelName: 'Баннеры КЕШБЭК «Оливье» и Новогодний КЕШБЭК',
    route: null
  },
  'slider-banners': {
    module: Banners,
    anchorIndex: 12,
    gtmLabelName: 'Баннеры с акциями от Ленты',
    route: 'banners',
    navLink: {
      name: '<span class="h5">Карта №1</span>'
    }
  },
  'map': {
    module: Map,
    anchorIndex: 13,
    gtmLabelName: 'Магазины «Лента» в вашем городе',
    route: 'map',
    navLink: {
      name: '<span class="h5">Магазины</span>'
    }
  },
  'footer': {
    module: Footer,
    anchorIndex: 14,
    gtmLabelName: 'Футер сайта',
    route: null
  },
  'filters': {
    module: GiftsConstructor,
    anchorIndex: 15,
    gtmLabelName: 'Блок с фильтрами',
    route: null
  },
  'actions': {
    module: ActionsPage,
    anchorIndex: 999,
    gtmLabelName: 'Акции от Ленты (Crazy)',
    route: null
  },
}

export default router;