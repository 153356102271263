import './Links.scss';

import React from 'react';
import GTM from '../ui/google/GTM';

type Props = React.PropsWithChildren<{
  to: string;
  className?: string;
  gtmAction?: string;
  gtmLabelName: string;
  withoutText?: boolean;
  onlyLabelName?: boolean;
  isCollectionLink?: any;
}>

export const LinkDefault = ({to, className, gtmAction = 'clickLink', gtmLabelName, withoutText, onlyLabelName, children, isCollectionLink}: Props) => {
  const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const target = e.target as HTMLAnchorElement;

    let {textContent} = target;

    let label = gtmLabelName;
    if (!onlyLabelName) label = `${gtmLabelName} | ${to}`;
    if (!withoutText && textContent) label = `${target.textContent} | ${label}`;

    if (isCollectionLink) {
      label = `Перейти к коллекции ( ${isCollectionLink.collectionName} ) | ${gtmLabelName} | ${isCollectionLink.collectionLink}`
    }

    GTM.push({
      action: gtmAction,
      label
    });
  }


  return <a href={to}
            onClick={onClick}
            onContextMenu={onClick}
            target={`_blank`}
            className={className || 'link-default'}>{children}</a>
}