import {createAsyncThunk} from "@reduxjs/toolkit";
import {http} from 'a-utils';
import {Catalog, State} from 'TYPES/State';

export const getCatalog = createAsyncThunk<Catalog[], void, {state: State}>(
  'blocks/catalog',
  async (arg, thunkAPI) => {
    const {api} = thunkAPI.getState().options;
    const {rejectWithValue} = thunkAPI;

    try {
      return await http<Catalog[]>({
        action: api + 'tree-products/'
      });
    } catch (error) {
      return rejectWithValue({});
    }
  });