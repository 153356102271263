import './solutions.scss'

import React from 'react';
import {useGTMLabel} from "HOOKS/useGTMLabel";
import {useSelector} from "STORE/store";
import {Slider} from '../slider/Slider';
import {LinkDefault} from "../links/LinkDefault";
import {SliderCard} from "../slider-card/SliderCard";



export const Solutions = ({module}) => {
  const label = useGTMLabel(module);
  const collection = useSelector(state => state.blocks.solutions);


  return (
      <div className="solutions">
          <div className="solutions__wrapper">
            <div className="solutions__content">

              <div className="solutions__heading heading heading--gradient wrapper">
                <h2 className="h2 ">
                  <span className="gradient">Наряжаем ёлку</span>
                </h2>
                <p className="heading__sign sign gradient">и украшаем дом</p>
              </div>

              <Slider
                  sliderType="cards-slider"
                  gtmLabelName={label}
                  className='card-slider wrapper'
                  slidesPerView={'auto'}
                  loop

                  bottomContent={<div className='solutions__buttons'>
                  </div>}
              >
                {collection?.map((card, index) => <SliderCard gtmLabelName={label} className="slider-card" {...card}   key={'ss-' + index}/>)}

              </Slider>
              {/*<div className="solutions__more-link">*/}
              {/*  <LinkDefault to={solutionsAllGoods.link} gtmLabelName={'solutions'} className='button button--white'>Смотреть все товары</LinkDefault>*/}
              {/*</div>*/}

            </div>
          </div>
        </div>
  );
};