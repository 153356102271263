import {createAsyncThunk} from "@reduxjs/toolkit";
import {http} from 'a-utils';
import {MapItem, State} from 'TYPES/State';

export const getMapItems = createAsyncThunk<MapItem[], string, {state: State}>(
  'geo/map-items',
  async (arg, thunkAPI) => {
    const {api} = thunkAPI.getState().options;
    const {rejectWithValue} = thunkAPI;

    try {
      return await http<MapItem[]>({
        action: `${api}shops/${arg}/`
      });
    } catch (error) {
      // if (error !== 422) throw error;
      return rejectWithValue({});
    }
  });