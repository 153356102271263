import './Header.scss';

import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import router from '../router';
import {CitiesPopupButton} from '../cities-popup/CitiesPopupButton';
import {useDispatch, useSelector} from 'STORE/store';
import {Modules} from 'TYPES/State';
import GTM from '../ui/google/GTM';
import {AnchorButton} from '../anchor/AnchorButton';
import {Simulate} from "react-dom/test-utils";
import {LinkBack} from "../links/LinkBack";

const links: Modules[] = ['gifts', 'solutions', 'catalog', 'partners', 'cashback',  'mobile-apps',  'map'];

export const Header = () => {
  const [isNavActive, setNavActive] = useState(false);
  const {modules} = useSelector(state => state.blocks);
  const pathName = window.location.pathname;

  const onClick = () => {
    setNavActive(!isNavActive);

    if (!isNavActive) {
      GTM.push({
        action: 'openMenuMobile',
        label: 'Открытие мобильного меню'
      })
    } else {
      GTM.push({
        action: 'closeMenuMobile',
        label: 'Закрытие мобильного меню'
      })
    }
  }

  let logo;
  if (window.location.pathname.length > 0) {
    logo = <AnchorButton className='header-logo' to='promo' gtmAction='clickElementLogo' gtmLabelName={'Клик по лого'}/>
  } else {
    logo = <Link className='header-logo' to='/'/>
  }

  useEffect(()=>{
    setTimeout(()=>{
      history.replaceState('', document.title, window.location.origin + window.location.pathname + window.location.search);
    },100)
  },[])

  if (pathName.replace('/', '').length > 0) {
    return <header>
      {logo}
      <nav className={isNavActive ? 'active' : undefined}>
        {links
            .filter((link) => modules.includes(link) && router[link].navLink)
            .map((link, index, array) => {

              return <React.Fragment key={link}>
                <LinkBack to={`/`}
                          className="nav-link"
                          gtmLabelName={`Шапка сайта | #header_-1`}
                          path={link}
                          html={router[link].navLink!.name}
                          onBackClick={setNavActive}
                />
                {index < array.length - 1 && <span className='circle'/>}
              </React.Fragment>;
            })
        }
        <button className='close-button nav-self-close' onClick={onClick}/>
      </nav>

      <CitiesPopupButton gtmLabelName='Шапка сайта'/>
      <button className='header-nav-button' onClick={onClick}>
        <i/><i/><i/>
      </button>
    </header>;
  } else {
    return <header>
      {logo}
      <nav className={isNavActive ? 'active' : undefined}>
        {links
            .filter((link) => modules.includes(link) && router[link].navLink)
            .map((link, index, array) => {

              return <React.Fragment key={link}>
                <AnchorButton className="nav-link"
                              to={link}
                              gtmLabelName={`Шапка сайта | #header_-1`}
                              html={router[link].navLink!.name}
                              mobileHandler={setNavActive}

                />
                {index < array.length - 1 && <span className='circle'/>}
              </React.Fragment>;
            })
        }
        <button className='close-button nav-self-close' onClick={onClick}/>
      </nav>
      <CitiesPopupButton gtmLabelName='Шапка сайта'/>
      <button className='header-nav-button' onClick={onClick}>
        <i/><i/><i/>
      </button>
    </header>
  }
}