import './Partners.scss';

import React, {useEffect, useState} from 'react';
import {Slider} from '../slider/Slider';
import {useDispatch, useSelector} from 'STORE/store';
import {getPartners} from 'STORE/thunks/partners-thunk';
import {BannerSlide} from '../banners/BannerSlide';
import {RouterModule} from '../router';
import {useGTMLabel} from 'HOOKS/useGTMLabel';

export const Partners: RouterModule = ({module}) => {
  const dispatch = useDispatch();
  const slides = useSelector(state => state.blocks.partners);
  const label = useGTMLabel(module);
  const [activeIndex, setActiveIndex] = useState(0 );

  useEffect(() => {
    dispatch(getPartners());
  }, []);


  // useEffect(()=>{
  //   console.log(activeIndex, 'activeIndex')
  //
  // }, [activeIndex])

  const handleActiveIndex = (index: number) =>{
    setActiveIndex(index)
  }


  if (!slides || !slides.length) return null;

  const names = slides.map(s => s.name);

  return <div className="partners dynamic-block">
    <div className="wrapper">
      {/*<h4>Получите скидки и подарки<br/> от наших друзей</h4>*/}
      <div className="partners__titles">
        {slides.map((s, i) => {
          return <div className={`partners__title partners__title-${activeIndex} ${i === activeIndex ? 'active' : ''}`} key={'partners-title-' + i}>
            <h4>{s.title}</h4>
          </div>
        })}
      </div>
      <div className="partners-slider">
        <Slider
            gtmLabelName={label}
            childrenNames={names}
            sliderType={'mono-slider'}
            pagination
            loop
            onSlideChange={handleActiveIndex}
        >
          {slides.map((slide, i) => {
            return <BannerSlide {...slide} gtmLabelName={label} key={'partners-item-' + i}/>
          })}


        </Slider>
      </div>




    </div>
  </div>
}