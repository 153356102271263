import React from 'react';
import {Modules} from "TYPES/State";
import router from "../../components/router";
import {Anchor} from "../../components/anchor/Anchor";
import {CrazyImage} from "../../components/crazy-image/CrazyImage";

const Crazy = () => {
    const modules:Modules[] = ['footer']

    return (
        <div className="crazy-wrap">

            <CrazyImage/>

            {modules.map((module, index) => {
                if (!router.hasOwnProperty(module)) return null;

                const routerItem = router[module];
                const Module = routerItem.module;

                return (
                    <Anchor
                        key={module}
                        module={module}
                    >
                        <Module module={module} />
                    </Anchor>
                )
            })}
        </div>


    );
};

export default Crazy;
