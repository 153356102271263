import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {Modules} from "TYPES/State";
import Gifts from '../pages/Gifts/Gifts';
import Main from "../pages/Main/Main";
import Crazy from "../pages/Crazy/Crazy";
import {ActionsPage} from "../components/actions-page/ActionsPage";
import Actions from "../pages/Actions/Actions";

type TProps = {
    modules: Modules[];
}


const RootRouter = (props: TProps) => {
    return (
        <Routes>
            <Route path={'/'} element={<Main modules={props.modules}/>} />

            <Route path={'/gifts'} element={<Gifts/>} />

            <Route path={'/crazy'} element={<Crazy/>} />

            <Route path={'/actions'} element={<Actions/>} />
        </Routes>
    );
};

export default RootRouter;
