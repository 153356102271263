import './CitiesPopup.scss';

import React from 'react';
import {setCitiesPopupActive} from 'STORE/slices/Geo';
import {useDispatch, useSelector} from 'STORE/store';
import GTM from '../ui/google/GTM';

type Props = React.PropsWithChildren<{
  gtmLabelName: string;
}>;

export const CitiesPopupButton = ({gtmLabelName, children}: Props) => {
  const {citiesPopupActive, activeCity} = useSelector(state => state.geo);
  const dispatch = useDispatch();

  const setPopupActive = (isActive: boolean) => {
    dispatch(setCitiesPopupActive(isActive));
  }

  const onClick = () => {
    setPopupActive(!citiesPopupActive);

    GTM.push({
      action: 'clickElement',
      label: `Выбор города | ${gtmLabelName}`
    });
  }

  return <button className='cities-popup-button' onClick={onClick}>{children} {activeCity.name}</button>
}