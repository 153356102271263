import './charity.scss';

import React, {useState} from 'react';
import {LinkDefault} from "../links/LinkDefault";
import {useGTMLabel} from "HOOKS/useGTMLabel";

export const Charity = ({module}) => {
  const label = useGTMLabel(module);
  return (
        <div className="charity">
          <div className="charity__wrapper wrapper">
            <div className="charity__clouds">
              <img src={require('./images/Clouds@2x.png')} alt=""/>
            </div>
            <div className="charity__content">
              <div className="charity__banner">
                {/*<LinkDefault*/}
                {/*    to={`https://moomin.lenta.com/`}*/}
                {/*    gtmLabelName={label}*/}
                {/*    className='charity__banner-link'*/}
                {/*    withoutText*/}
                {/*>*/}
                {/*  <img src={require('./images/Banner-light@2x.jpg')} alt=""/>*/}
                {/*</LinkDefault>*/}
                <div className='charity__banner-link'>
                  <img src={require('./images/Banner-light@2x.jpg')} alt=""/>
                </div>
              </div>
              {/*<LinkDefault*/}
              {/*    to={`https://moomin.lenta.com/`}*/}
              {/*    gtmLabelName={label}*/}
              {/*    className='charity__button button button--white'*/}
              {/*>Перейти на сайт</LinkDefault>*/}
            </div>
          </div>
        </div>
  );
};
