declare global {
  interface Window {
    dataLayer: DataLayer[];
  }
}
declare const dataLayer: DataLayer<{event: 'eventTracking'; category: string;}>[];

export type DataLayer<T = {}> = {
  action?: string;
  label?: string;
  non_interaction?: boolean;
  user_hash?: string;
  [key: string]: string | number | undefined | boolean;
} & T


class GTM {
  _dataLayerName = 'dataLayer';
  _initialized = false;
  _created = false;

  constructor() {
    this.create();
  }
  // create(dataLayer = this._dataLayerName) {
  create() {
    if (this._created) return;

    // this._dataLayerName = dataLayer;

    const script = document.createElement('script');
    script.id = 'GTM-datalayer';
    script.innerHTML = `${this._dataLayerName} = []`;
    document.head.appendChild(script);

    this._created = true;
  }
  initialize(key: string) {
    if (this._initialized) return;

    const script = document.createElement('script');
    script.id = 'GTM-core';
    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='${this._dataLayerName}'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${key}');`;
    // script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    //   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    //   j=d.createElement(s),dl=l!='${this._dataLayerName}'?'&l='+l:'';j.async=true;j.src=
    //   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    //   })(window,document,'script','dataLayer','GTM-KCNWG4Z');`;
    document.head.appendChild(script);

    const noScript = document.createElement('noscript');
    noScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${key}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    // noScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KCNWG4Z" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.insertBefore(noScript, document.body.firstChild);

    this._initialized = true;
  }
  push(data: DataLayer) {
    if (!this._created) return;

    let category = 'landing:new-year';
    let categoryChapter = window.location.pathname;
    if (categoryChapter != '/') {
      category = `${category}|${categoryChapter.replace('/','')}`;
    }

    dataLayer.push({
      event: 'eventTracking',
      category,
      non_interaction: false,
      ...data
    });
  }
}

export default new GTM();