import React from 'react';
import {render} from 'react-dom';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {Provider} from 'react-redux';

import App from './components/App';
import store from './store/store';

import {OutLiner} from 'a-utils';
new OutLiner();

render(
  <GoogleReCaptchaProvider reCaptchaKey={'6Ld8ut0cAAAAAHwRayJBnIaH2fPdGtsulkymKb0o'}>
    <Provider store={store}>
      <App/>
    </Provider>
  </GoogleReCaptchaProvider>,
  document.getElementById('root')
);
// import 'components/statistics/test'