import {createAsyncThunk} from "@reduxjs/toolkit";
import {http} from 'a-utils';
import {Modules, State} from 'TYPES/State';

export const getModules = createAsyncThunk<Modules[], undefined, {state: State}>(
  'blocks/modules',
  async (arg, thunkAPI) => {
    const {api} = thunkAPI.getState().options;
    const {rejectWithValue} = thunkAPI;

    try {
      return await http<Modules[]>({
        action: api + 'blocks/'
      });
    } catch (error) {
      // if (error !== 422) throw error;
      return rejectWithValue({});
    }
  });