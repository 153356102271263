import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AnchorsState, Modules} from 'TYPES/State';


const initialState: AnchorsState = {
  active: undefined
};

const anchorsSlice = createSlice({
  name: 'anchors-reducer',
  reducers: {
    setActive: (state: AnchorsState, action: PayloadAction<Modules | undefined>) => {
      state.active = action.payload;
    },
  },
  initialState: initialState
});

export const {setActive} = anchorsSlice.actions;
export default anchorsSlice;