import './collection.scss';

import React from 'react';
import {LinkDefault} from "../links/LinkDefault";


export const CollectionItem = (props) => {
  const label = props.gtmLabelName;
  const propsData =
    {
      itemName: props.name,
      itemLink: props.url,
      itemSign: props.sign,
      itemImage: props.image
    };

  const sign = propsData.itemSign

  return (
      <li>
        <LinkDefault to={propsData.itemLink}
                     gtmAction='clickLink'
                     gtmLabelName={`${propsData.itemName} |  ${label}`}
                     withoutTextchildren
                     className='collection__link'>
              <span className="collection__image">
              <span className="collection__back">
                  <svg  viewBox="0 0 198 198">
                    <path d="M121.823 4.37802C132.053 -1.18734 144.819 3.97052 148.312 15.0803L152.913 29.7145C155.061 36.5448 160.467 41.8579 167.334 43.8858L182.046 48.2306C193.215 51.5291 198.595 64.2032 193.209 74.5289L186.115 88.1302C182.803 94.4783 182.87 102.058 186.291 108.348L193.622 121.823C199.187 132.053 194.029 144.819 182.92 148.312L168.286 152.913C161.455 155.061 156.142 160.467 154.114 167.334L149.769 182.046C146.471 193.215 133.797 198.595 123.471 193.209L109.87 186.115C103.522 182.803 95.9416 182.87 89.6522 186.291L76.1768 193.622C65.9467 199.187 53.1806 194.029 49.6877 182.92L45.0868 168.286C42.9394 161.455 37.5327 156.142 30.666 154.114L15.9537 149.769C4.78467 146.471 -0.595195 133.797 4.79078 123.471L11.8854 109.87C15.1966 103.522 15.1305 95.9416 11.7089 89.6522L4.37804 76.1768C-1.18731 65.9467 3.97055 53.1806 15.0803 49.6877L29.7145 45.0868C36.5448 42.9393 41.8579 37.5327 43.8858 30.6659L48.2306 15.9537C51.5291 4.78465 64.2033 -0.595221 74.5289 4.79075L88.1302 11.8853C94.4784 15.1966 102.058 15.1305 108.348 11.7089L121.823 4.37802Z" stroke="url(#paint0_linear_239:3422)" strokeWidth="3"/>
                    <defs>
                      <linearGradient id="paint0_linear_239:3422" x1="72.1249" y1="-16.6951" x2="156.198" y2="212.917" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#D1DEEC"/>
                        <stop offset="1" stopColor="#BDCDE1"/>
                      </linearGradient>
                    </defs>
                  </svg>
              </span>
                <img src={propsData.itemImage} alt={propsData.itemName}/>
              </span>
              <span className="collection__sign" dangerouslySetInnerHTML={{__html: sign}} >
              </span>
        </LinkDefault>
      </li>
  );
};