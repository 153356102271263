import React from 'react';
import router from "../../components/router";
import {Modules} from "TYPES/State";
import {Anchor} from "../../components/anchor/Anchor";
import {AnchorPopup} from "../../components/anchor-popup/AnchorPopup";
import {Link} from "react-router-dom";

type TProps = {
    modules: Modules[];
}

const Main = (props: TProps) => {
    const modules:Modules[] = ['promo', 'calendar', 'crazy-link', 'gifts', 'solutions', 'catalog', 'music', 'partners', 'mobile-apps', 'cashback', 'map', 'footer']

    return (
        <div className="global-wrapper">
            <AnchorPopup/>
            <div className="anchors">
                {modules.map((module, index) => {
                    if (!router.hasOwnProperty(module)) return null;

                    const routerItem = router[module];
                    const Module = routerItem.module;

                    return (
                        <Anchor
                            key={module}
                            module={module}
                        >
                            <Module module={module} />
                        </Anchor>
                    )
                })}
            </div>
        </div>

    );
};

export default Main;
