import React from 'react';
import {Banner} from 'TYPES/State';
import {LinkDefault} from '../links/LinkDefault';
import './Banners.scss';

type Props = Banner & {
  gtmLabelName: string;
};

export const BannerSlide = ({image, name, gtmLabelName, url}: Props) => {
  let returned = <img src={image} alt=''/>;

  if (url) {
    returned = <LinkDefault className='banners__item-link' withoutText gtmLabelName={`${name} | ${gtmLabelName}`} to={url}>{returned}</LinkDefault>
  }

  return <div className='banners-item'>

    {returned}
  </div>
}