import './actions-popup.scss'

import React, {useEffect} from 'react';

export const ActionsPopup = ({className, content, onSelfClose}) => {
  const clickHandler = (e) => {
    let target = e.target;
    if (!target.closest('.actions-popup__content')){
      onSelfClose(false);
    }
  }
  const clickButtonHandler = ()=>{
    onSelfClose(false);
  }

  const onEscKeyDown = (evt) => {
    if (evt.key === 'Escape' || evt.key === 'Esc') {
      evt.preventDefault();
      onSelfClose(false);
    }
  };

  useEffect(()=>{
    document.addEventListener('keydown', onEscKeyDown);
    return window.removeEventListener("keydown", onEscKeyDown);
  }, [])

  let resultLayout;
  if (content) {
    let description =
        <div className="actions-popup__description">
          <div className="actions-popup__text-main">
            {content.title}
          </div>
          <div className="actions-popup__text-medium">
            {content.restriction}
          </div>
          {/*{content.modalData.medium.map((text, index)=> <div className="actions-popup__text-medium" key={`t-${index}`}>{text}</div>)}*/}
          {content.description &&
          <div className="actions-popup__text-sign">
            {content.description}
          </div>
          }
        </div>
    resultLayout =
        <div className="actions-popup__content">
          <div className="close-button" onClick={clickButtonHandler}/>
          <div className="actions-popup__left">
            <div className="actions-popup__image">
              {content.sale &&
              <div className="actions-popup__card-label actions-popup__label">
                <div className="actions-popup__label-top">{content.sale}<small>%</small></div>
                {/*{content.label.sign && <div className="actions-popup__label-sign">{content.label.sign}</div>}*/}
              </div>
              }
              {content.image && <img src={content.image} alt=""/>}
            </div>
          </div>
          <div className="actions-popup__right">
            <div className="actions-popup__date">
              {content.day}
            </div>
            {description}
          </div>
        </div>
  }


  return (
      <div className={className} onClick={clickHandler}>
        <div className="actions-popup__wrapper">
          {resultLayout}
        </div>
      </div>
  );
};
