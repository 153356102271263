import './gifts-page.scss'

import React from 'react';
import {Link} from "react-router-dom";
import GTM from "../ui/google/GTM";

export const GiftsPageBanner = () => {
  return (
      <div className="gifts-page gp">
        <div className="gp__prolog">
          <div className="gp__prolog-wrapper wrapper">
            <div className="gp__prolog-heading heading heading--gifts-page heading--blue">
              <Link to="/" style={{color: '#074690', marginTop: '10px', marginBottom: '10px', fontWeight: 500}}>На главную</Link>
              <div className="heading__titles">
                <h2 className="h2"><span>Выбираем</span></h2>
                <h2 className="h2"><span>новогодние подарки</span></h2>
              </div>
              <div className="heading__sign">
                <p className="sign">Найти идеальный вариант поможет наш конструктор</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};
