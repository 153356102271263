import React from 'react';
import {LinkDefault} from "../links/LinkDefault";
import {useGTMLabel} from "HOOKS/useGTMLabel";

export const ActionsBanner = ({module}) => {
  const label = useGTMLabel(module);
  return (
      <div className="actions-banner">
        <LinkDefault to={'https://lenta.com/gift-cards/'} gtmLabelName={label} withoutText>
          <img src={require('./banner@2x.png')} alt={`Дарите подарочную карту «Лента»`}/>
        </LinkDefault>
        
      </div>
  );
};
