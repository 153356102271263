import gsap from 'gsap';
import {EventEmitter} from 'a-utils';


class ScrollTo extends EventEmitter<'start' | 'complete'> {
  private _isPlaying: boolean;
  private _tween?: gsap.core.Tween;
  private _y: number

  constructor() {
    super();

    this._isPlaying = false;
    this._y = 0;

    document.addEventListener('wheel', () => this.stop());
    document.addEventListener('mousedown', () => this.stop());
    document.addEventListener('touchstart', () => this.stop());
  }

  public toPos(newY: number, duration?: number) {
    const oldY = window.scrollY;
    this._y = oldY;

    if (!duration) {
      duration = Math.abs((newY - oldY) / document.documentElement.clientHeight * 0.2);

      duration = duration < 0.4 ? 0.4 : duration;
    }

    this._tween = gsap.fromTo(this, {
      _y: oldY
    }, {
      _y: newY,
      duration,
      onStart: () => {
        this._isPlaying = true;
        this.dispatch('start');
      },
      onUpdate: () => {
        window.scrollTo(0, this._y);
      },
      onComplete: () => {
        this._isPlaying = false;
        this.dispatch('complete');
      }
    })
  }
  public toSelector(selector: keyof HTMLElementTagNameMap, padding = 0, duration?: number) {
    this.toElem(document.querySelector(selector), padding, duration)
  }
  public toElem(el: HTMLElementTagNameMap[keyof HTMLElementTagNameMap] | null, padding = 0, duration?: number) {
    if (!el) return;

    const bounds = el.getBoundingClientRect();
    const scrolled = window.scrollY;

    this.toPos(bounds.top + scrolled + padding, duration);
  }

  public stop() {
    if (this._tween) {
      this._tween.kill();
      this._tween = undefined;
      this.dispatch('complete');
    }
  }
}


const scrollTo = new ScrollTo();

export default scrollTo;