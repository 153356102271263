import React, {useEffect, useState} from 'react';
import {Select} from "../form/select/Select";
import GTM from "../google/GTM";

const selectDefaultItem = {
  id: 'anyone',
  name: 'Любому',
  items: null,
}
const secondDefaultItems = {
  id: 'anyone',
  name: 'Выбрать',
  items: null,
}

export const SmartSelect = ({categories, onSmartSelectChange, gtmLabelName, ...props}) => {
  const [firstSelectValue, setFirstSelectValue] = useState(selectDefaultItem.id);
  const [secondSelectValue, setSecondSelectValue] = useState(secondDefaultItems.id);
  const [isAlreadyInited, toggleInitFlag] = useState(false);

  let firstSelectItems = [
    selectDefaultItem,
    ...categories
  ]

  let secondSelectItems
  /**
   * В случае если выбрано "Любому" - items === null
   */
  if (firstSelectValue === 'anyone' || firstSelectValue === null) {
    secondSelectItems = categories.reduce((finalItems, {items}) => {
      if (!!items?.length) {
        return [...finalItems, ...items]
      } else {
        return finalItems
      }
    }, [selectDefaultItem])
  } else {
    secondSelectItems = [secondDefaultItems, ...categories.find(category => category.id === firstSelectValue).items]
  }

  // костыль, чтобы первый селект не пушился в gtm разметку
  useEffect(()=>{
    toggleInitFlag(true);
  },[])

  useEffect(()=>{
    onSmartSelectChange({category: null});

    let currentSelectedItem = firstSelectItems.find(category => category.id === firstSelectValue).name
    if (currentSelectedItem){
      let query = `Выбрана опция «${currentSelectedItem}» (главная категория) | ${gtmLabelName};`;

      if (isAlreadyInited){
        GTM.push({
          action: `clickTabs`,
          query
        });
      }
    }
  },[firstSelectValue])

  useEffect(()=>{
    let currentSelectedItem = secondSelectItems.find(category => category.id === secondSelectValue).name
    if (currentSelectedItem){
      let query = `Выбрана опция «${currentSelectedItem}» (второстепенная категория) | ${gtmLabelName};`
      if (isAlreadyInited){
        GTM.push({
          action: `clickTabs`,
          query
        });
      }
    }
  },[secondSelectValue])

  const onFirstSelectChanged = (ids) => {
    setFirstSelectValue(ids[0])
    setSecondSelectValue(selectDefaultItem.id)
    onSmartSelectChange({parent_category: ids[0]});
  }

  const onSecondSelectChanged = (ids) => {
    setSecondSelectValue(ids[0])
    onSmartSelectChange({category: ids[0]});
  }

  return (
      <div className="smart-select">
        <input type="hidden" name="parent_category" className="visually-hidden" value={[firstSelectValue]}/>
        <input type="hidden" name="category" className="visually-hidden"  value={[secondSelectValue]}/>
        <Select items={firstSelectItems}
                className='select-wrapper--gifts'
                value={[firstSelectValue]}
                defaultValue={['anyone']}
                onChange={onFirstSelectChanged}
        />
        <Select items={secondSelectItems}
                className='select-wrapper--gifts'
                value={[secondSelectValue]}
                defaultValue={['anyone']}
                onChange={onSecondSelectChanged}
        />
      </div>

  );
};
