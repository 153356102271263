import React, {useState} from 'react';
import {Modules} from 'TYPES/State';
import GTM from '../ui/google/GTM';
import {getAnchor} from 'HOOKS/useGTMLabel';
import {setActive} from 'STORE/slices/Anchors';
import {useDispatch} from 'STORE/store';
import useGlobalDOMEvents from "HOOKS/useGlobalDOMEvents";


type Props = React.PropsWithChildren<{
  gtmAction?: string;
  gtmLabelName: string;
  className?: string;
  to: Modules;
  html?: string;
  mobileHandler?: any;
  onCloseHandler?: any;
}>


export const AnchorButton = ({gtmAction, gtmLabelName, to, className, children, html, mobileHandler}: Props) => {
  const dispatch = useDispatch();
  const [isMobileT, setIsMobileT] = useState(window.innerWidth < 1025);

  useGlobalDOMEvents({
    'resize': () => {
      setIsMobileT(window.innerWidth < 1025)
    }
  });

  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const target = e.target as HTMLButtonElement;
    dispatch(setActive(to));

    if (isMobileT) mobileHandler(false);
    if (!target) return;

    if (!gtmAction) {
      GTM.push({
        action: 'clickAnchor',
        label: `${target.textContent || 'Нет текста в кнопке'} | ${gtmLabelName}${getAnchor(to)}`
      });
    } else {
      GTM.push({
        action: gtmAction,
        label: gtmLabelName
      });
    }
  }

  return <button className={className}
                 onClick={onClick}
                 onContextMenu={onClick}
                 dangerouslySetInnerHTML={html ? {__html: html} : undefined}
  >
    {children}
  </button>
}