import {createAsyncThunk} from "@reduxjs/toolkit";
import {http} from 'a-utils';
import {Gifts, State} from 'TYPES/State';

export const getGifts = createAsyncThunk<Gifts[], void, {state: State}>(
    'blocks/gifts',
    async (arg, thunkAPI) => {
        const {api} = thunkAPI.getState().options;
        const {rejectWithValue} = thunkAPI;

        try {
            return await http<Gifts[]>({
                action: api + 'slider-gifts/'
            });
        } catch (error) {
            return rejectWithValue({});
        }
    });