import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Forms, MyPurchases, SubmitStatus} from 'TYPES/State';

const initialState: Forms = {
  myPurchases: null,
  submitPopupActive: false,
  submitStatus: null
};

const formsSlice = createSlice({
  name: 'forms-reducer',
  reducers: {
    setPurchases: (state: Forms, action: PayloadAction<MyPurchases>) => {
      state.myPurchases = action.payload;
    },
    setSubmitPopupActive: (state: Forms, action: PayloadAction<boolean>) => {
      state.submitPopupActive = action.payload;

      if (!action.payload) {
        state.submitStatus = null;
      }
    },
    setSubmitStatus: (state: Forms, action: PayloadAction<SubmitStatus>) => {
      state.submitStatus = action.payload;
    }
  },
  // async reducers
  extraReducers: (builder) => {
    // builder.addCase(getCities.pending, (state, action) => {
    //   console.log('loading cities');
    // })
    // builder.addCase(getCities.fulfilled, (state: Options, action) => {
    //   state.cities = action.payload;
    //   state.citiesLoaded = true;
    // })
    // builder.addCase(getCities.rejected, (state: Options, action) => {
    //   console.log(action);
    // })
  },
  initialState: initialState
});

export const {setPurchases, setSubmitPopupActive, setSubmitStatus} = formsSlice.actions;
export default formsSlice;