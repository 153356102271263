import {createAsyncThunk} from "@reduxjs/toolkit";
import {http} from 'a-utils';
import {ServiceKeys, State} from 'TYPES/State';

export const getServiceKeys = createAsyncThunk<ServiceKeys, void, {state: State}>(
  'options/service-keys',
  async (arg, thunkAPI) => {
    const {api} = thunkAPI.getState().options;
    const {rejectWithValue} = thunkAPI;

    try {
      return await http<ServiceKeys>({
        action: api + 'service-keys/'
      });
    } catch (error) {
      // if (error !== 422) throw error;
      return rejectWithValue({});
    }
  });