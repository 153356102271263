import {combineReducers} from 'redux';
import {State} from 'TYPES/State';
import optionsSlice from '../slices/Options';
import blocksSlice from 'STORE/slices/Blocks';
import geoSlice from 'STORE/slices/Geo';
import formsSlice from 'STORE/slices/Forms';
import anchorsSlice from 'STORE/slices/Anchors';

export default combineReducers<State>({
  options: optionsSlice.reducer,
  blocks: blocksSlice.reducer,
  forms: formsSlice.reducer,
  geo: geoSlice.reducer,
  anchors: anchorsSlice.reducer
});