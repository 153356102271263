import './crazy-link.scss'

import React from 'react';
import {Link} from "react-router-dom";

export const CrazyLink = () => {
  const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  return (
      <div className="crazy-link">
        <div className="crazy-link__wrapper">
          <div className="crazy-link__content">
            <h4 className="h4 gradient">Хотите больше скидок?</h4>
            <div className="crazy-link__button-holder">
              <svg className={isChrome ? 'animated' : ''} width="75" height="96" viewBox="0 0 75 96" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path className="p1" d="M10.2502 89.8753C34 73.5 56.5 47.5 60 13.5" stroke="#FFB138" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="100" strokeDashoffset="0"/>
                <path className="p2" d="M10.251 89.8753C15.1285 80.5 17.9899 75 18.6074 73.8131L10.251 89.8753Z" stroke="#FFB138" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="100" strokeDashoffset="0"/>
                <path className="p3"  d="M10.2491 89.8753L27.6055 87.9375" stroke="#FFB138" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="50" strokeDashoffset="0"/>
              </svg>
              <Link className="button button--white" to="/actions">
                <span>Смотреть скидки</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
  );
};