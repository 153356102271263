import {createAsyncThunk} from "@reduxjs/toolkit";
import {http} from 'a-utils';
import {Actions, State} from 'TYPES/State';
// import {getParameterByName} from "../../components/utils/getParameterByName";

export const getActions = createAsyncThunk<Actions[], void, {state: State}>(
    'blocks/actions',
    async (arg, thunkAPI) => {
        const {api} = thunkAPI.getState().options;
        const {rejectWithValue} = thunkAPI;

        // const urlSearchParams = new URLSearchParams(window.location.search);
        // const params = Object.fromEntries(urlSearchParams.entries());
        // const returnedValue = params.day.replace('/','')

        try {
            return await http<Actions[]>({
                action: api + `crazy/`
            });
        } catch (error) {
            return rejectWithValue({});
        }
    });