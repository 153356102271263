import 'swiper/css';
import './Slider.scss';

import React, {useRef, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Pagination, EffectFade, Controller, Autoplay} from 'swiper';
import {Swiper as SwiperClass} from 'swiper/types';
import GTM from '../ui/google/GTM';
import useGlobalDOMEvents from "HOOKS/useGlobalDOMEvents";
import {getSlidesPerView} from "../utils/getSlidesPerView";

import 'swiper/css';
import 'swiper/css/effect-fade';

type Props = {
  children: React.ReactElement | React.ReactElement[];
  childrenNames?: string[],
  className?: string;
  slidesPerView?: 'auto' | number;
  slidesPerGroup?: number;
  initialSlide?: number;
  pagination?: boolean;
  controls?: boolean;
  bottomContent?: React.ReactElement | React.ReactElement[];
  row?: boolean;
  loop?: boolean;
  gtmLabelName: string;
  spaceBetween?: number;
  sliderType?: 'cards-slider' | 'mono-slider' | 'products-slider' | 'windows-adapt' | 'fade-slider' | "swiper";
  onSlideChange?: any;
  controller?: any;
}


export const Slider = ({children, childrenNames, className, controls = true, pagination, bottomContent, loop, gtmLabelName, slidesPerView, slidesPerGroup, spaceBetween = 0, sliderType = 'mono-slider', initialSlide, onSlideChange, controller}: Props) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1025);
  const [slidesPerViewCounter, setSlidesPerViewCounter] = useState<'auto' | number>();
  const classNameRandom = useRef('sb-' + Math.round(Math  .random() * 1000));
  const isInit = useRef(false);
  useGlobalDOMEvents({
    'resize': () => {
      setIsMobile(window.innerWidth < 1025)
      setSlidesPerViewCounter(getSlidesPerView());
    }
  });

  if (!Array.isArray(children)) children = [children];

  let modules = [Navigation, Pagination];

  let w = 'slider-wrapper';
  let c = 'slider';
  if (className) c = `${c} ${className}`;

  const prev = <button className={`slider-button prev ${classNameRandom.current}`}>
    <svg width="32" height="34" viewBox="0 0 32 34" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.5454 33.1213C20.7169 34.2929 22.6164 34.2929 23.788 33.1213C24.9596 31.9497 24.9596 30.0503 23.788 28.8787L19.5454 33.1213ZM7.66668 17L5.54536 14.8787L3.42404 17L5.54537 19.1213L7.66668 17ZM23.788 5.12132C24.9596 3.94974 24.9596 2.05025 23.788 0.878677C22.6164 -0.292894 20.7169 -0.292894 19.5454 0.878678L23.788 5.12132ZM23.788 28.8787L9.78801 14.8787L5.54537 19.1213L19.5454 33.1213L23.788 28.8787ZM9.78801 19.1213L23.788 5.12132L19.5454 0.878678L5.54536 14.8787L9.78801 19.1213Z"/>
    </svg>
  </button>;
  const next = <button className={`slider-button next ${classNameRandom.current}`}>
    <svg width="32" height="34" viewBox="0 0 32 34"  xmlns="http://www.w3.org/2000/svg">
      <path d="M12.4547 0.87868C11.2831 -0.292893 9.38363 -0.292893 8.21205 0.87868C7.04048 2.05025 7.04048 3.94975 8.21205 5.12132L12.4547 0.87868ZM24.3334 17L26.4547 19.1213L28.576 17L26.4547 14.8787L24.3334 17ZM8.21205 28.8787C7.04048 30.0503 7.04048 31.9497 8.21205 33.1213C9.38363 34.2929 11.2831 34.2929 12.4547 33.1213L8.21205 28.8787ZM8.21205 5.12132L22.2121 19.1213L26.4547 14.8787L12.4547 0.87868L8.21205 5.12132ZM22.2121 14.8787L8.21205 28.8787L12.4547 33.1213L26.4547 19.1213L22.2121 14.8787Z"/>
    </svg>
  </button>;
  const paginationBlock = <div className={`slider-pagination ${classNameRandom.current}`}/>

  const onChange = (swiper: SwiperClass) => {
    let label = gtmLabelName;
    // костыль, рубим первое событие при инициализации
    if (!isInit.current) {
      isInit.current = true;
      return;
    }

    GTM.push({
      action: 'useSlider',
      label
    });

    if (onSlideChange){
      onSlideChange(swiper.realIndex);
    }
  }

  let bottom;
  let slider;

  if (children.length) {
    if (sliderType === 'products-slider'){

      slider = <div className={c}>
        <Swiper
            loop={loop}
            loopAdditionalSlides={0}
            modules={modules}
            touchRatio={isMobile ? 1 : 0}
            navigation={{
              prevEl: `.slider-button.prev.${classNameRandom.current}`,
              nextEl: `.slider-button.next.${classNameRandom.current}`
            }}
            speed={300}
            slidesPerView={getSlidesPerView()}
            onSlideChange={onChange}
            spaceBetween={spaceBetween}
            initialSlide={initialSlide ? initialSlide : 0}
        >

          {children.map((child, i) => (
              <SwiperSlide
                  key={'sws-' + i}>
                {child}
              </SwiperSlide>
          ))}
        </Swiper>
      </div>
    } else if (sliderType === 'cards-slider'){
      slider = <div className={c}>
        <Swiper
            loop={loop}
            modules={modules}
            touchRatio={isMobile ? 1 : 0}
            navigation={{
              prevEl: `.slider-button.prev.${classNameRandom.current}`,
              nextEl: `.slider-button.next.${classNameRandom.current}`
            }}
            speed={600}
            slidesPerView={slidesPerView}
            onSlideChange={onChange}
            spaceBetween={isMobile ? 20 : 80}
            initialSlide={initialSlide ? initialSlide : 0}
        >

          {children.map((child, i) => (
              <SwiperSlide
                  key={'sws-' + i}>
                {child}
              </SwiperSlide>
          ))}
        </Swiper>
      </div>
    } else if (sliderType === 'mono-slider'){
      modules.push(EffectFade, Controller, Autoplay);

      slider = <div className={c}>
        <div className="partners-slider">
          <Swiper
              loop={loop}
              // autoplay={{delay: 8000}}
              // loopAdditionalSlides={1}
              modules={modules}
              touchRatio={isMobile ? 1 : 0}
              navigation={{
                prevEl: `.slider-button.prev.${classNameRandom.current}`,
                nextEl: `.slider-button.next.${classNameRandom.current}`
              }}
              pagination={{
                el: `.slider-pagination.${classNameRandom.current}`,
                type: 'bullets',
                bulletElement: 'div',
              }}
              speed={500}
              slidesPerView={slidesPerView ? slidesPerView : 1}
              onSlideChange={onChange}
              spaceBetween={spaceBetween ? spaceBetween : 0}
              effect="fade"
              fadeEffect={{ crossFade: true}}
              autoHeight={true}
          >
            {children.map((child, i) => (
                <SwiperSlide
                    key={'sws-' + i}>
                  {child}
                </SwiperSlide>
            ))}
          </Swiper>


        </div>
      </div>
    } else if (sliderType === 'windows-adapt'){
      slider = <div className={c}>
        <Swiper
            loop={loop}
            modules={modules}
            touchRatio={window.innerWidth < 1025 ? 1 : 0}
            navigation={{
              prevEl: `.slider-button.prev.${classNameRandom.current}`,
              nextEl: `.slider-button.next.${classNameRandom.current}`
            }}
            pagination={{
              el: `.slider-pagination.${classNameRandom.current}`,
              type: 'bullets',
              bulletElement: 'div',
            }}
            speed={400}
            onSlideChange={onChange}
            spaceBetween={spaceBetween}
            initialSlide={initialSlide ? initialSlide : 0}
        >

          {children.map((child, i) => (
              <SwiperSlide
                  key={'sws-' + i}>
                {child}
              </SwiperSlide>
          ))}
        </Swiper>
      </div>
    } else {
      // modules.push(EffectFade);
      slider = <div className={c}>
        <Swiper
            loop={loop}
            // modules={modules}
            touchRatio={isMobile ? 1 : 0}
            navigation={{
              prevEl: `.slider-button.prev.${classNameRandom.current}`,
              nextEl: `.slider-button.next.${classNameRandom.current}`
            }}
            pagination={{
              el: `.slider-pagination.${classNameRandom.current}`,
              type: 'bullets',
              bulletElement: 'div',
            }}
            speed={500}
            slidesPerView={slidesPerView ? slidesPerView : 1}
            onSlideChange={onChange}
            spaceBetween={spaceBetween ? spaceBetween : 0}
            initialSlide={initialSlide ? initialSlide : 0}
            effect="fade"
            // onSwiper={setControlledSwiper}
        >
          {children.map((child, i) => (
              <SwiperSlide
                  key={'sws-' + i}>
                {child}
              </SwiperSlide>
          ))}
        </Swiper>
      </div>
    }
  }

  let finishLayout;
  if (sliderType === "products-slider"){
    finishLayout =
        <div className={w}>
          {isMobile &&
          <div className="catalog-slider-holder">
            {slider}
            {bottomContent}
          </div>
          }
          {
            !isMobile &&
            <div className="catalog-slider-holder">
              {slider}
              {bottomContent}
              {prev}
              {next}
            </div>
          }
        </div>
  } else if (sliderType === 'mono-slider') {
    finishLayout =
        <div className={w}>
          {slider}
          {!isMobile && prev}
          {!isMobile && next}
          <div className="slider-tools slider-tools--patners">
            {prev}
            {paginationBlock}
            {next}
          </div>
        </div>
  } else if (sliderType === 'windows-adapt') {
    finishLayout =
        <div className={w}>
          {slider}
          <div className="slider-tools">
            {prev}
            {paginationBlock}
            {next}
          </div>
        </div>
  } else if (sliderType === 'cards-slider'){
    finishLayout = isMobile ?
        <div className={w}>
          {slider}
        </div>
        :
        <div className={w}>
          {slider}
          {prev}
          {next}
        </div>
  } else {
    finishLayout =
        <div className={w}>
          {slider}
        </div>
  }


  return (
      <div className="slider-container">
        {finishLayout}
      </div>

  );
};