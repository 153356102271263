import './gift-labels.scss';

import React from 'react';
import GTM from "../ui/google/GTM";


export const GiftsLabels = ({labels, onChange, gtmLabelName}) => {
  let listOfLabels = [];
  for (let key in labels){
    listOfLabels.push({
      id: labels[+key].id,
      name: labels[+key].name,
    });
  }

  const handleChange = ({target}) => {
    onChange(target.name, target.checked)
    let query = `Выбран таб «${target.dataset.query}» | ${gtmLabelName}`;
    if (target.checked){
      GTM.push({
        action: 'clickTabs',
        query
      });
    }
  }

  return (
      <ul className="gift-labels gl">
        {listOfLabels.map((giftLabel, index) =>
            <li
                className="gl__elem"
                key={'gift-item-' + index}
            >
              <input className="visually-hidden" type="checkbox" name={giftLabel.id} id={giftLabel.id} data-query={giftLabel.name} onChange={handleChange}/>
              <label className="gl__button" htmlFor={giftLabel.id}>
                <span className="gl__icon"/>
                <span className="gl__sign">{giftLabel.name}</span>
              </label>
            </li>
        )}
      </ul>
  );
};
