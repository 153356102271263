import './filters.scss';
import './results.scss'

import React, {useRef, useState} from 'react';
import {useSelector} from "STORE/store";
import {useGTMLabel} from "HOOKS/useGTMLabel";
import {GiftsFiltersForm} from "../gifts-filters-form/GiftsFiltersForm";
import {Loader} from "../loader/Loader";
import {Slider} from "../slider/Slider";
import {LinkPDF} from "../links/LinkPDF";
import {LinkDefault} from "../links/LinkDefault";
import {ProductCard} from "../product-card/ProductCard";

export const GiftsConstructor = ({module}) => {
  const label = useGTMLabel(module);
  const giftsFilter = useSelector(state => state.blocks.filters);
  const results = useRef();
  const [isLoading, setStatusLoading] = useState(false);
  const [isVisible, setVisibility] = useState(false);
  const [isError, setErrorStatus] = useState(false);
  const [currentBlockHeight, setBlockHeight] = useState(0);
  const [catalog, setCatalogList] = useState([]);

  const onVisibilityChange = (e) =>{
    setVisibility(e)
  }

  const onHeightChange = (isResponse) =>{
    let div = results.current;
    let scrollPosition = window.innerWidth > 1024 ? 'center' : 'start';

    if (isResponse){
      setBlockHeight(div.offsetHeight)
    } else {
      if (currentBlockHeight === 0) {
        setBlockHeight(300);
      }
    }
    div.scrollIntoView({block: scrollPosition, behavior: "smooth"});
  }

  let anotherCard = {
    url: 'https://lenta.com/gift-cards/',
    name: 'asd',
    image: 'undefined',
    specialType: true,
  }
  let arrayWithGiftCard = [anotherCard].concat(catalog);


  let sliderContent;
  let sliderTemplate =
    <Slider
      gtmLabelName={label}
      loop
      className='four catalog-slider catalog-slider--trees slider--white-back'
      sliderType='products-slider'
      spaceBetween={window.innerWidth > 1024 ? 40 : 20}
      bottomContent={
        <div className='slider__buttons'>
          <LinkDefault
              to={'https://catalogs.lenta.com/hyper/ADDAR-2021-hmc?version=V1&page=1'}
              gtmLabelName={label}
              className='button button--transparent'
          >
            Ещё больше подарков
          </LinkDefault>
        </div>
      }
  >
    {arrayWithGiftCard?.map((s, i) => <ProductCard {...s} gtmLabelName={label} key={'pc-' + i}/>)}
  </Slider>

  if (window.innerWidth < 768){
    if (catalog.length === 1){
      sliderContent =
          <div className="catalog-slider catalog-slider--less">
            {arrayWithGiftCard?.map((s, i) => <ProductCard {...s} gtmLabelName={label} key={'pc-' + i}/>)}
          </div>
    } else{
      sliderContent = sliderTemplate
    }
  } else {
    if (catalog.length > 4){
      sliderContent = sliderTemplate
    } else if (catalog.length <= 4){
      sliderContent =
          <div>
            <div className="catalog-slider catalog-slider--less catalog-slider--trees slider--white-back">
              {catalog?.map((s, i) => <ProductCard {...s} gtmLabelName={label} key={'pc-' + i}/>)}
            </div>
            <div className='slider__buttons'>
              <LinkDefault
                  to={'https://catalogs.lenta.com/hyper/ADDAR-2021-hmc?version=V1&page=1'}
                  gtmLabelName={label}
                  className='button button--transparent'
              >
                Ещё больше подарков
              </LinkDefault>
            </div>
          </div>
    } else if (!catalog.length){
      sliderContent = null;
    }
  }


  return (
      <div>
        <div className="filters">
          <div className="filters__wrapper wrapper">
            <div className="filters__content">
              <GiftsFiltersForm
                  {...giftsFilter}
                  gtmLabelName={label}
                  loadStatus={setStatusLoading}
                  visibleStatus={onVisibilityChange}
                  errorStatus={setErrorStatus}
                  setCatalogList={setCatalogList}
                  setIsSending={setStatusLoading}
                  calcBlockHeight={onHeightChange}
              />
            </div>
          </div>
        </div>

        <div className={`results ${isVisible ? 'active' : ''}`} style={{height: currentBlockHeight}}>
          <div className="results__wrapper">
            <div className="results__content">
              {isLoading && <div className={`results__preloader`}>
                <Loader/>
              </div>}
              <div className="results__slider" ref={results}>
                {(!isLoading && catalog.length > 0) && sliderContent}
              </div>

              {isError &&
              <div className={`results__error`}>
                <p>По заданным параметрам товаров не нашлось :(</p>
                <p>Может взглянете на наш каталог?</p>
                <LinkDefault
                    to={'https://catalogs.lenta.com/hyper/ADDAR-2021-hmc?version=V1&page=1'}
                    gtmLabelName={label}
                    className='button button--transparent'
                >Перейти в каталог
                </LinkDefault>
              </div>
              }
            </div>
          </div>
        </div>

      </div>
  );
};
