import {createAsyncThunk} from "@reduxjs/toolkit";
import {http} from 'a-utils';
import {Banner, State} from 'TYPES/State';

export const getBanners = createAsyncThunk<Banner[], void, {state: State}>(
  'blocks/banners',
  async (arg, thunkAPI) => {
    const {api} = thunkAPI.getState().options;
    const {rejectWithValue} = thunkAPI;

    try {
      return await http<Banner[]>({
        action: api + 'slider-banners/'
      });
    } catch (error) {
      // if (error !== 422) throw error;
      return rejectWithValue({});
    }
  });