import './crazy.scss';

import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {useSelector} from "STORE/store";
import useGlobalDOMEvents from "HOOKS/useGlobalDOMEvents";

export const CrazyImage = () => {
  const crazyData = useSelector(state => state.blocks.crazy);
  const [isMobileT, setIsMobileT] = useState(window.innerWidth < 1025);

  useGlobalDOMEvents({
    'resize': () => {
      setIsMobileT(window.innerWidth < 1025)
    }
  });

  return (
      <div className="crazy">
        <div className="crazy__wrapper">
          <div className="crazy__content">
            <div className="crazy__image">
              {crazyData?.map((crazyImage, index) =>
                  <div className="asd" key={`crazy-${index}`}>
                    {isMobileT && <img src={crazyImage.mobileImage ? crazyImage.mobileImage : crazyImage.image} alt={index}/>}
                    {!isMobileT &&  <img src={crazyImage.image} alt={index}/>}
                  </div>
              )}
            </div>
            <div className="wrapper">
              <Link className="crazy__link" to="/" style={{color: '#074690', marginTop: '10px', marginBottom: '10px', fontWeight: 500}}>На главную</Link>
            </div>
          </div>
        </div>
      </div>
  );
};