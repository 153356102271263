import React from 'react';
import {Modules} from "TYPES/State";
import router from "../../components/router";
import {Anchor} from "../../components/anchor/Anchor";
import {CrazyImage} from "../../components/crazy-image/CrazyImage";
import {ActionsPage} from "../../components/actions-page/ActionsPage";

const Crazy = () => {
    const modules:Modules[] = ['actions', 'footer']

    return (
        <div className="actions-wrapper">
            {/*<div className="actions-parallax">*/}
            {/*    <div className="parallax">*/}
            {/*        <div className="parallax__layer parallax__layer--back">*/}
            {/*            <div>BACK</div>*/}
            {/*        </div>*/}
            {/*        <div className="parallax__layer parallax__layer--base">*/}
            {/*            <div>BASE</div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*<ActionsPage/>*/}

            {modules.map((module, index) => {
                if (!router.hasOwnProperty(module)) return null;

                const routerItem = router[module];
                const Module = routerItem.module;

                return (
                    <Anchor
                        key={module}
                        module={module}
                    >
                        <Module module={module} />
                    </Anchor>
                )
            })}
        </div>


    );
};

export default Crazy;
