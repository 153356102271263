import React, {useRef, useState} from 'react';
import {SmartSelect} from "../ui/smart-select/SmartSelect";
import {GiftsLabels} from "../gifts-labels/GiftsLabels";
import {PriceSlider} from "../ui/price-slider/PriceSlider";
import {ButtonLoader} from "../button-loader/ButtonLoader";
import {http} from 'a-utils/src/http/http';
import querystring from 'query-string';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import { useSelector} from 'STORE/store';
import { elementScrollIntoViewPolyfill } from "seamless-scroll-polyfill";
import GTM from "../ui/google/GTM";

export const GiftsFiltersForm = ({loadStatus, visibleStatus, errorStatus, setCatalogList, setIsSending, calcBlockHeight, gtmLabelName, ...props}) => {
  elementScrollIntoViewPolyfill();
  const api = useSelector((state) => state.options.api);
  const {executeRecaptcha} = useGoogleReCaptcha();
  const [formValues, setFormValues] = useState({
    category: null,
    parent_category: null,
    selections: [],
  })

  const filtersProps = {
    smartSelect: props.categories || [],
    prices: props.prices || [],
    selections: props.selections || []
  }

  const stringFilter = {
    ...formValues,
    selections: formValues.selections.join(',')
  }

  const handleValueChange = (formPart) => {
    setFormValues({
      ...formValues,
      ...formPart
    })
  }
  const handlePriceChange = (prices) => {
    setFormValues({
      ...formValues,
      min: prices.min,
      max: prices.max
    })
  }

  const handleSelectionsChange = (key, value) => {
    const shouldAdd = value === true
    setFormValues({
      ...formValues,
      selections: shouldAdd
          ? [...formValues.selections, key]
          : formValues.selections.filter(val => val !== key)
    })
  }

  const onSubmit = (e) => {
    e.preventDefault();
    loadStatus(true);
    visibleStatus(true);
    // setButtonLoading(true);
    errorStatus(false);
    let finalObject = {};
    for (let key in stringFilter){
      if (stringFilter[key] && stringFilter[key] !== 'anyone'){
        if (key === 'min'){
          finalObject['price_from'] = stringFilter[key];
        } else if (key === 'max'){
          finalObject['price_to'] = stringFilter[key];
        } else {
          finalObject[key] = stringFilter[key];
        }
      }
    }

    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
      setIsSending(true);
      const data = querystring.stringify(finalObject);

      GTM.push({
        action: 'clickElement',
        label: `Подобрать (показаны товары) | ${gtmLabelName}`,
      })

      http({
        action: api + 'catalog/?' + data,
      }).then((response) => {
        setIsSending(false);
        errorStatus(false);
        setCatalogList(response);

        if (response.length > 0 && response.length) {
          calcBlockHeight(true);
          visibleStatus(true);
          errorStatus(false);
        } else {
          calcBlockHeight(false);
          errorStatus(true);
        }
      }).catch((error) => {
        setIsSending(false)
        if (error.status === 400) {
          error.json().then((response) => {
            if (response.errors) {
              let messages = [];
              let newState = {...state};
              for (let key in response.errors) {
                const k = typeof state;
                const error = response.errors[k];
                if (k in newState) {
                  const message = Array.isArray(error) ? error.join(' ') : error;
                  messages.push(message);
                  newState = {
                    ...newState,
                    [k]: {
                      ...newState[k],
                      error: true,
                      notice: message
                    }
                  }
                }
              }
              setState(newState);
            }
          })
        } else {
          // emitError(error.message)
        }
      })
  }

  return (
      <form onSubmit={onSubmit} className="filters__form form form--filters">
        <div className="filters__top">
          <div className="filters__smart-select filters__stroke">
            <div className="filters__label-title">Кому?</div>
            <SmartSelect categories={filtersProps.smartSelect} onSmartSelectChange={handleValueChange} gtmLabelName={gtmLabelName}/>
          </div>
          <div className="filters__slider filters__stroke">
            <div className="filters__label-title">Цена</div>
            <PriceSlider prices={filtersProps.prices} onPriceSliderChange={handlePriceChange} gtmLabelName={gtmLabelName}/>
          </div>
        </div>
        <div className="filters__bottom">
          <div className="filters__gift-labels filters__stroke">
            <div className="filters__label-title">Тип</div>
            <GiftsLabels labels={filtersProps.selections} onChange={handleSelectionsChange} gtmLabelName={gtmLabelName}/>
          </div>
        </div>
        <div className="filters__submit-block">
          <img className="filters__line filters__line--left" src={require('./images/line-left.svg')} alt=""/>
          {/*<ButtonLoader className={`button ${isButtonLoading ? 'loading' : ''}`}/>*/}
          {/*<ButtonLoader className={`button ${isButtonLoading ? 'disabled' : ''}`}/>*/}
          <ButtonLoader className={`button`}/>
          <img className="filters__line filters__line--right" src={require('./images/line-right.svg')} alt=""/>
        </div>
      </form>
  );
};
