import './slider-card.scss'
import React, {useState, useEffect, useRef} from 'react';
import {LinkDefault} from "../links/LinkDefault";
import {StarButton} from "./star-button/StarButton";
import {SliderCardItem} from "./slider-card-item/SliderCardItem";
import GTM from "../ui/google/GTM";


export const SliderCard = (card) => {
  const [activeStar, setActiveStar] = useState(null)
  const [isExtended, setExtend] = useState(false)
  const minHeight = 60;
  const [resultHeight, setMaxHeight] = useState();
  const cardList = card.cardsList;
  const isToggleNeed = cardList.length > 1;
  let toggleWrap = useRef();
  let toggleListOfItems = useRef();
  let maxHeight = toggleListOfItems.current?.clientHeight;
  let openedTimeout;
  let label = card.gtmLabelName;

  const gtmInfo = {
    gtmLabelTitle: card.gtmLabelTitle,
    collectionName: card.collectionName,
    collectionLink: card.collectionLink
  }

  const toggleList = (e) => {
    console.log(e.target.textContent);
    GTM.push({
      action: 'clickElement',
      label: `${e.target.textContent} - ${gtmInfo.collectionName} | ${label}`
    });

    setExtend(!isExtended);
    setMaxHeight(!isExtended ? maxHeight : minHeight);
  }

  const toggleStar = (value) => {
    if (value === undefined) {
      setActiveStar(!activeStar)
    } else {
      setActiveStar(value)
    }
  }

  const hideAllStars = () => {
    setActiveStar(null);
  }

  const onLeave = () =>{
    openedTimeout = setTimeout(() => {
      hideAllStars();
    },500)
  }

  const onEnter = () =>{
    clearTimeout(openedTimeout);
  }

  useEffect(()=>{
    setMaxHeight(isExtended ? maxHeight : minHeight);
  },[])

  return (
      <div className="slider-card" onMouseLeave={onLeave}  onMouseEnter={onEnter}>
        <div className="slider-card__wrap">
          <div className="slider-card__image" style={{backgroundImage: `url(${card.imageRef})`}}>
            {card.cardsList.map((card, index) => (
                <StarButton
                    {...card}
                    key={'sb-' + index}
                    dataIndex={index}
                    activeStar={activeStar}
                    toggleStar={() => toggleStar(index)}
                    onClick={() => toggleStar(index)}
                    gtmLabelName={label}
                    collection={gtmInfo.collectionName}
                />
            ))}
          </div>
          <div className="slider-card__content">
            <h3 className="slider-card__title">{`«${card.collectionName}»`}</h3>
            <div className={`slider-card__toggle-holder ${isToggleNeed ? 'with-button' : '' } ${isExtended ? 'show' : 'collapse' }`}>
              <div className="slider-card__toggle-wrap" ref={toggleWrap} style={{maxHeight: resultHeight}}>
                <ul className="slider-card__toggle-list" ref={toggleListOfItems}>
                  {window.innerWidth < 1025 && card.cardsList.map((card, index) => (
                      <SliderCardItem
                          {...card}
                          className="slider-card__item"
                          key={`sc-m ${index}`}
                          dataIndex={index}
                          gtmLabelName={label}
                          collection={gtmInfo.collectionName}
                      />
                  ))}
                </ul>
              </div>
              <button type="button" className={`slider-card__toggle-button${isExtended ? ' triggered' : ''}`} onClick={toggleList}>
                <span className="text">{isExtended ? 'Свернуть' : 'Показать все товары'}</span>
              </button>
            </div>
            <LinkDefault to={card.collectionLink} className="slider-card__link button button--transparent"  gtmLabelName={card.gtmLabelName} isCollectionLink={gtmInfo}>
              <span>Перейти к коллекции</span>
            </LinkDefault>
          </div>
        </div>
      </div>
  );
};