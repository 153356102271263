import './moo.scss'

import React from 'react';
import {LinkDefault} from "../links/LinkDefault";
import {useGTMLabel} from "HOOKS/useGTMLabel";

export const MoominBanner = ({module}) => {
  const label = useGTMLabel(module);

  return (
      <section className="section section--white moomin-banner moo">
        <div className="moo__wrapper wrapper">
          <div className="moo__il-bottom-left">
            <div><img src={require('./images/cloud-bottom-left@2x.png')} alt=""/></div>
          </div>
            <div className="moo__content">
              <h4 className="h4">Время муми-трогательных<br/>подарков!</h4>
              <div className="moo__banner">
                <LinkDefault
                    to={`https://moomin.lenta.com/`}
                    gtmLabelName={`Клик по баннеру | ${label}`}
                    className='moo__banner-link'
                    withoutText
                >
                  <img src={require('./images/m-banner@2x.jpg')} alt=""/>
                </LinkDefault>
              </div>
              <LinkDefault
                  to={`https://moomin.lenta.com/`}
                  gtmLabelName={label}
                  className='moo__button button button--blue'
              >Перейти на сайт</LinkDefault>
            </div>
      </div>
      </section>
  );
};