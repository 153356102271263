import React from 'react';
import GTM from "../ui/google/GTM";
export const ActionsCard = ({image, name, restriction, description, sale, title, clickHandler, day, gtmLabelName}) => {

  const modalFields =
      {
        name,
        image,
        title,
        restriction,
        description,
        day
      }

  const showFixedPopup = (e) => {
    clickHandler(true, modalFields);

    GTM.push({
      action: 'clickElement',
      label: `${e.target.textContent} - ${title} | ${gtmLabelName}`,
      non_interaction: true
    });
  }

  return (
      <li className="actions__card" onClick={showFixedPopup}>
        <div className="actions__card-wrapper">
          {sale &&
          <div className="actions__card-label actions__label">
            <div className="actions__label-top">{sale}<small>%</small></div>
            {/*{label.sign && <div className="actions__label-sign">{label.sign}</div>}*/}
          </div>
          }
          <div className="actions__card-image">
            {image && <img src={image ? image : ''} alt="item-picture"/>}
          </div>
          <div className="actions__card-title">{title}</div>
          <div className="actions__card-sign">{restriction}</div>
          <button className="actions__popup-clicker button button--transparent-white" type="button">
            <span>Подробнее</span>
          </button>
        </div>
      </li>
  );
};
