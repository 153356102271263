import React from 'react';
import {Modules} from "TYPES/State";
import router from "../../components/router";
import {GiftsPageBanner} from "../../components/gifts-page-banner/GiftsPageBanner";
import {Anchor} from "../../components/anchor/Anchor";

const Gifts = () => {
    const modules:Modules[] = ['filters', 'actions-banner', 'mobile-apps', 'cashback', 'map', 'footer']

    return (
        <div className="filters-wrap">

            <GiftsPageBanner/>

            {modules.map((module, index) => {
                if (!router.hasOwnProperty(module)) return null;

                const routerItem = router[module];
                const Module = routerItem.module;

                return (
                    <Anchor
                        key={module}
                        module={module}
                    >
                        <Module module={module} />
                    </Anchor>
                )
            })}
        </div>


    );
};

export default Gifts;
