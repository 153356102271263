import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Options} from 'TYPES/State';
import {getServiceKeys} from 'STORE/thunks/keys-thunk';
// import {getPdfs} from 'STORE/thunks/pdfs-thunk';

const meta: (HTMLMetaElement | null) = document.querySelector('meta[name=api]');
const api: string = meta ? meta.getAttribute('content')! : '';
const titleEl = document.querySelector('title');
const title: string = titleEl ? titleEl.innerText + ' | ' : '';

const initialOptionsState: Options = {
  api,
  domain: window.location.origin,
  title,
  keys: null,
  // pdfs: {}
};

const optionsSlice = createSlice({
  name: 'options-reducer',
  reducers: {
    // addNotice: (state: Options, action: PayloadAction<Notice>) => {
    //   state.notices.push({
    //     ...action.payload,
    //     id: 'notice-' + Math.random()
    //   })
    // },
    // removeNotice: (state: Options, action: PayloadAction<string>) => {
    //   state.notices = state.notices.filter((notice) => notice.id !== action.payload);
    // }
  },
  // async reducers
  extraReducers: (builder) => {
    builder.addCase(getServiceKeys.pending, (state, action) => {
      console.log('loading service keys');
    })
    builder.addCase(getServiceKeys.fulfilled, (state: Options, action) => {
      state.keys = action.payload;
    })
    builder.addCase(getServiceKeys.rejected, (state: Options, action) => {
      console.log(action);
    })

    // builder.addCase(getPdfs.pending, (state, action) => {
    //   console.log('loading pdfs');
    // })
    // builder.addCase(getPdfs.fulfilled, (state: Options, action) => {
    //   state.pdfs = action.payload;
    // })
    // builder.addCase(getPdfs.rejected, (state: Options, action) => {
    //   console.log(action);
    // })
  },
  initialState: initialOptionsState
});

export default optionsSlice;