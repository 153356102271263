import './price-slider.scss'
import React, {useEffect, useRef, useState} from 'react';
import Nouislider from "nouislider-react";
import "nouislider/dist/nouislider.css";

export const PriceSlider = ({prices, onPriceSliderChange}) => {
  const minPrice = prices.min ? parseFloat(prices.min) : 1;
  const maxPrice = prices.max ? parseFloat(prices.max) : 100;
  const [minValue, setMinValue] = useState(minPrice);
  const [maxValue, setMaxValue] = useState(maxPrice);

  const onChange = (e) => {
    setMinValue(e[0]);
    setMaxValue(e[1]);
  }

  useEffect(() => {
    onPriceSliderChange({
      min: minValue,
      max: maxValue
    });
  }, [minValue, maxValue])


  useEffect(() => {
    if (!minPrice || !maxPrice) {
      setMinValue(minPrice)
      setMaxValue(maxPrice)
    }
  }, [minPrice, maxPrice])

  return (
      <div className="price-slider">
        <Nouislider
            // range={props ? { min: +minValue, max: +maxValue} : defaultValue}
            range={{min: minPrice, max: maxPrice}}
            start={[ minPrice, maxPrice ]}
            connect
            onUpdate={onChange}
        />
        <div className="price-slider__results">
          <div className="price-slider__result-price">
            <span>от </span>
            <span className="price">{minValue}</span>
            <input type="hidden" value={minValue} name="min"/>
          </div>
          <div className="price-slider__result-price">
            <span>до </span>
            <span className="price">{maxValue}</span>
            <input type="hidden" value={minValue} name="max"/>
          </div>
        </div>
      </div>
  );
};
