import './Links.scss';

import React from 'react';
import GTM from '../ui/google/GTM';
import {Link} from "react-router-dom";
import {setActive} from "STORE/slices/Anchors";
import {useDispatch} from "STORE/store";

export const LinkBack = ({to, className, gtmAction = 'clickMenu', gtmLabelName, html, path, onBackClick}) => {
  const dispatch = useDispatch();

  const onClick = (e) => {
    e.preventDefault();
    onBackClick(false);

    setTimeout(()=>{
      dispatch(setActive(path));
    }, 1500)

    GTM.push({
      action: gtmAction,
      label: `${e.target.textContent}`,
    });
  }

  return <Link to={'/'}
               onClick={onClick}
               className={className || 'link-default'}
               dangerouslySetInnerHTML={html ? {__html: html} : undefined}>
  </Link>
}