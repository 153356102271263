import {createAsyncThunk} from "@reduxjs/toolkit";
import {http} from 'a-utils';
import {Solutions, State} from 'TYPES/State';

export const getSolutions = createAsyncThunk<Solutions[], void, {state: State}>(
  'blocks/solutions',
  async (arg, thunkAPI) => {
    const {api} = thunkAPI.getState().options;
    const {rejectWithValue} = thunkAPI;

    try {
      return await http<Solutions[]>({
        action: api + 'collections/'
      });
    } catch (error) {
      return rejectWithValue({});
    }
  });