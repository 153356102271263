import './MobileApps.scss';

import React, {useState} from 'react';
import {LinkDefault} from '../links/LinkDefault';
import {RouterModule} from '../router';
import {useGTMLabel} from 'HOOKS/useGTMLabel';
import useGlobalDOMEvents from "HOOKS/useGlobalDOMEvents";


export const MobileApps: RouterModule = ({module}) => {
  const label = useGTMLabel(module);
  const [isMobileT, setIsMobileT] = useState(window.innerWidth < 768);


  useGlobalDOMEvents({
    'resize': () => {
      setIsMobileT(window.innerWidth < 768)
    }
  });


  return <div className="mobile-apps">
    <div className="mobile-apps__wrapper wrapper">
      <div className="mobile-apps__content">

        <div className="mobile-apps-content">
          <h4 className="mobile-apps__title">
            О, товары<br/>
            к Новому году<br/>
            в Лента Онлайн!
          </h4>

          <LinkDefault className='button button--white'
                       gtmLabelName={label}
                       to='https://le.onelink.me/3F8J/NewYear22'>Заказать</LinkDefault>
          <p>Скачать приложение</p>
          <div className="mobile-apps-download">
            <LinkDefault className='mobile-apps-apple'
                         gtmLabelName={`AppStore | ${label}`}
                         withoutText
                         gtmAction='goToAppStore'
                         to='https://apps.apple.com/ru/app/лента-онлайн-заказ-продуктов/id1482112922'>
              <img src={require('./images/apple.svg')} alt=""/>
            </LinkDefault>
            <img src={require('./images/qr.svg')} alt=""/>
            <LinkDefault className='mobile-apps-google'
                         gtmLabelName={`Google Play | ${label}`}
                         withoutText
                         gtmAction='goToAppStore'
                         to='https://play.google.com/store/apps/details?id=ru.lenta.lentochka'>
              <img src={require('./images/goole.svg')} alt=""/>
            </LinkDefault>
          </div>
        </div>

        <div className="mobile-apps-bg">
          {!isMobileT &&
          <div className="mobile-apps__phone mobile-apps__phone--desktop">
            <img src={require('./images/phone--desktop@2x.png')} alt=""/>
          </div>
          }
          {isMobileT &&
          <div className="mobile-apps__phone mobile-apps__phone--mobile">
            <img src={require('./images/phone--mobile@2x.png')} alt=""/>
          </div>
          }
          <div className="mobile-apps__snowflake-big">
            <img src={require('./images/snowflake--middle@2x.png')} alt=""/>
          </div>
          <div className="mobile-apps__snowflake-middle">
            <img src={require('./images/snowflake--middle@2x.png')} alt=""/>
          </div>
          <div className="mobile-apps__snowflake-small">
            <img src={require('./images/snowflake--middle.png')} alt=""/>
          </div>
          <div className="mobile-apps__clouds">
            {/*<img width="787" height="346" src={require('./images/mobile-clouds@2x.png')} alt=""/>*/}
            <img src={require('./images/Illustration@2x.png')} alt=""/>
          </div>
          <div className="mobile-apps__good mobile-apps__good--1">
            <img src={require('./images/good1@2x.png')} alt=""/>
          </div>
          <div className="mobile-apps__goods">
            <div className="mobile-apps__good mobile-apps__good--2">
              <img src={require('./images/good2@2x.png')} alt=""/>
            </div>
            <div className="mobile-apps__good mobile-apps__good--3">
              <img src={require('./images/good3@2x.png')} alt=""/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}