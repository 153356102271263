import './Footer.scss';

import React from 'react';
import {LinkDefault} from '../links/LinkDefault';
import router, {RouterModule} from '../router';
import {useSelector} from 'STORE/store';
import {useGTMLabel} from 'HOOKS/useGTMLabel';

const socials = [
  {
    image: require('./socials/1.svg'),
    url: 'https://www.facebook.com/lentacom/',
    name: 'Facebook'
  },
  {
    image: require('./socials/2.svg'),
    url: 'https://vk.com/lentacom',
    name: 'Вконтакте'
  },
  {
    image: require('./socials/3.svg'),
    url: 'https://ok.ru/group/53286834208943',
    name: 'Одноклассники'
  },
  {
    image: require('./socials/4.svg'),
    url: 'https://www.instagram.com/lenta_com/',
    name: 'Instagram'
  },
  // {
  //   image: require('./socials/5.svg'),
  //   url: '',
  //   name: 'Youtube'
  // }
]


export const Footer: RouterModule = ({module}) => {
  const label = useGTMLabel(module)

  return <footer>
    <div className="footer__wrapper wrapper">
      <div className="footer-item">
        <div className="footer-title">2021 «ЛЕНТА»</div>
        <div className="footer-text">Все права защищены</div>
      </div>
      <div className="footer-item footer-item-center">
        <div className="footer-title">8 800 700 4111</div>
        <div className="footer-text">Центр информационной поддержки</div>
      </div>
      <div className="footer-item footer-nets">
        {socials.map((s, i) =>
            <LinkDefault key={'fn-' + i}
                         gtmAction='goToSocialMedia'
                         gtmLabelName={`${s.name} | ${label}`}
                         onlyLabelName
                         className='footer-nets-item'
                         to={s.url}>
              <img alt='' src={s.image}/>
            </LinkDefault>)}
      </div>
    </div>
  </footer>
}